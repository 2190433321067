import DataTable from "react-data-table-component";
import {useEffect, useState} from "react";
import BreadCrumb from "../Breadcrumb/BreadCrumb";

const DataTableLayout = (props) =>{
    const dataTableSetting = props.dataTableSetting;
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState('');

    const [search, setSearch] = useState(dataTableSetting.search ?? '');
    const [sortField, setSortField] = useState(dataTableSetting.sortField ?? 'id');
    const [sortDirection, setSortDirection] = useState(dataTableSetting.sortDirection ?? 'desc');
    const [pageSize, setPageSize] = useState(dataTableSetting.pageSize ?? '10');
    const [page, setPage] = useState(dataTableSetting.page ?? '1');

    useEffect(() => {
        if(props.data){
            setLoading(false);
            setData([...props.data]);
            setTotal(props.totalRows);
        }
    }, [props.data,props.total]);
    // Handle sorting change
    const handleSort = (column, direction) => {
        setSortField(column.selector_field);
        setSortDirection(direction);
    };
    useEffect(()=>{
        props.fetchData({search, sortField,sortDirection, page, pageSize});
    },[search, sortField,sortDirection, page, pageSize])
    // Handle pagination change
    const handlePageChange = (page) => {
        setPage(page);
    };

    // Handle change in rows per page
    const handlePageSizeChange = (newPerPage, page) => {
        setPageSize(newPerPage);
        setPage(page);
    };
    return (<>
        <div className='table-container' ref={props.tableContainerRef}>

            <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={e => setSearch(e.target.value)}
            />
            <DataTable
                columns={props.columns}
                data={data}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={total}
                paginationDefaultPage={page}
                paginationPerPage={pageSize}
                onChangeRowsPerPage={handlePageSizeChange}
                onChangePage={handlePageChange}
                onSort={handleSort}
                defaultSortFieldId={1}
                defaultSortAsc={true}
                customStyles={{
                    header: {
                        style: {
                            position: "sticky",
                            top: 0,
                            backgroundColor: "#f8f9fa", // Header background color
                            borderBottom: "1px solid #ddd",
                        },
                    },
                    headCells: {
                        style: {
                            position: "sticky",
                            top: 0,
                            backgroundColor: "#f8f9fa", // Ensure alignment with row headers
                            borderBottom: "1px solid #ddd",
                            fontWeight: "bold",
                        },
                    },
                    rows: {
                        style: {
                            overflow: "visible",
                        },
                    },
                }}
            />
        </div>
    </>)
}
export default DataTableLayout;
