import React, { useEffect, useState } from 'react';
import { makeApiCall } from '../../../hooks/api/makeApiCall';
import { indianDateFormat } from "../../../store/utility/DatatableUtility";

const HolidayList = () => {
    const [holidays, setHolidays] = useState([]);
    const [today] = useState(new Date().toISOString().split('T')[0]); // Today's date

    // Fetch holiday list
    useEffect(() => {
        const fetchHolidays = async () => {
            const response = await makeApiCall('/gallery/get-holiday-list');
            setHolidays(response);
        };
        fetchHolidays();
    }, []);

    // Get first upcoming holiday
    const firstUpcomingHoliday = holidays.find(
        (holiday) => new Date(holiday.holiday_date) >= new Date(today)
    );

    return (
        <div className="container mx-auto px-4 py-6 mt-2">
            <h1 className="text-2xl font-bold text-center mb-6 text-gray-800">Holiday List</h1>
            <div className="flex flex-col gap-4">
                {holidays?.map((holiday) => {
                    const isPassed = new Date(holiday.holiday_date) < new Date(today);
                    const isFirstUpcoming =
                        firstUpcomingHoliday &&
                        holiday.holiday_date === firstUpcomingHoliday.holiday_date;

                    return (
                        <div
                            key={holiday.id}
                            className={`p-4 rounded-lg shadow-md transition-all duration-300 holiday-item ${
                                isPassed
                                    ? 'bg-gray-200 text-gray-500 passed-holiday'
                                    : isFirstUpcoming
                                        ? 'bg-green-100 text-green-700 font-semibold first-upcoming-holiday'
                                        : 'bg-white text-gray-800 '
                            }`}
                        >
                            <div className="flex justify-between items-center">
                                <div className="holiday-date text-sm font-medium">
                                    {indianDateFormat(holiday.holiday_date)}
                                </div>
                                <div className="holiday-day text-xs italic">
                                    {holiday.Day}
                                </div>
                            </div>
                            <div className="holiday-name text-lg mt-2">
                                {holiday.name}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HolidayList;
