import React, {useMemo, useState,  useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBox,
    faBoxes,
    faDownload,
    faFileArrowDown,
    faHandshake,
    faHandshakeSlash
} from "@fortawesome/free-solid-svg-icons";
import {useFetchData} from "../../../hooks/useFetchData";
import {actionToGetCompanyListApiCall, callSoftDeleteDataWithLogFunction} from "../../../store/action/commonActions";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import useAuth from "../../../hooks/useAuth";
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import LeadToOrderFormModal from "../../Sales/lead-to-order/LeadToOrderFormModal";
import CreateSalesOrderForm from "./CreateSalesOrderForm";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {indianDateFormat, indianDateTimeFormat, truncateHTMLText} from "../../../store/utility/DatatableUtility";
import Modal from "react-modal";


const OfflineOrderTable = ({ setFetchDataTable, fetchDataTable, selectedClientLead }) => {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const { data: offlineOrder, fetchData, settings } = useFetchData('sales/get-lead-order', [],{ client_lead_id: selectedClientLead?.id });

    const [isEditLeadOrderOpen, setIsEditLeadOrderOpen] = useState(null);
    const [selectedLeadOrder, setSelectedLeadOrder] = useState(null);
    const [isPreviewRemarksOpen, setIsPreviewRemarksOpen] = useState(false);

    // Memoize the columns for performance
    const columns = useMemo(() => {
        const baseColumns = [
            { name: 'Sr No', selector: (row, index) => index + 1, width: '60px' },
            { name: 'Company Name', selector: row => row.company_name, sortable: true, width: '150px' },
            { name: 'Document Type', selector: row => row.document_type, sortable: true, width: '200px' },
            {
                name: 'Document',
                selector: row => row.relevant_document,
                sortable: false,
                width: '100px',
                cell: row => (
                    <Tippy content="Download Order Copy">
                    <a href={row.relevant_document} download target="_blank" rel="noopener noreferrer">
                        <button className='action-button'>
                            <FontAwesomeIcon className='action-icon' icon={faFileArrowDown} />
                        </button>
                    </a></Tippy>
                )
            },
            { name: 'Description', selector: row =>
                    <div className="truncate-text" title={row.remark} onClick={() => { setSelectedLeadOrder(row); setIsPreviewRemarksOpen('open_remark_preview'); }}>
                        {truncateHTMLText(row.description, 50)} {/* Adjust maxLength as needed */}
                    </div>, sortable: true, width: '250px' },
            { name: 'Sales Person', selector: row => row.sales_person, sortable: true, width: '200px' },
            { name: 'Order Date',selector: row => row.order_date && indianDateFormat(row.order_date), selector_field:'order_date',sortable: true, width: '200px' },
            {
                name: 'Actions', cell: (row) => (
                    <div className='action-components'>
                        <TableEditActions
                            tooltip={"Edit Lead Order"}
                            row={row}
                            onEdit={() => { setSelectedLeadOrder(row); setIsEditLeadOrderOpen('edit'); }}
                        />
                        <TableDeleteActions
                            tooltip={"Delete Lead Order"}
                            row={row}
                            onDelete={() => dispatch(callSoftDeleteDataWithLogFunction({
                                tableName: 'lead_order',
                                id: row.id,
                                userId: auth?.id,
                                eventTypeId
                            })).then(fetchData)}
                        />
                        {!selectedClientLead && !row.order_id && <Tippy content="Create Order">
                            <button className='action-button' onClick={() => { setSelectedLeadOrder(row);  setIsEditLeadOrderOpen('sales_order_form'); }}>
                                <FontAwesomeIcon className='action-icon' icon={faBox} />
                            </button>
                        </Tippy> }

                    </div>
                ), width: '350px'
            }
        ];

        if (!selectedClientLead) {
            baseColumns.splice(1, 0, { name: 'School', selector: row => row.school_name, sortable: true, width: '200px' });
            baseColumns.splice(1, 0,   { name: 'Order Name', selector: row => row.lead_name, sortable: true, width: '150px' },);
        }

        return baseColumns;
    }, [selectedClientLead, offlineOrder]);

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable, fetchData]);
    useEffectOnce(()=>{
        dispatch(actionToGetCompanyListApiCall({in_sales_use:1}));
    })

    return (
        <>
            <DataTableLayout keyField="id" columns={columns} data={offlineOrder.data} dataTableSetting={settings} fetchData={fetchData} totalRows={offlineOrder?.total} />
           {isEditLeadOrderOpen === 'edit' && (
                <LeadToOrderFormModal
                    handleExternalFetch={fetchData}
                    isOpen
                    onRequestClose={() => setIsEditLeadOrderOpen(null)}
                    selectedData={selectedLeadOrder}
                    isEditMode
                />
            )}
           {isEditLeadOrderOpen === 'sales_order_form' && (
                <CreateSalesOrderForm  isOpen
                                       handleExternalFetch={fetchData}
                                       onRequestClose={() => setIsEditLeadOrderOpen(null)}
                                       selectedData={{lead_id:selectedLeadOrder.id,shipping_address_id:selectedLeadOrder.shipping_address_id,billing_address_id:selectedLeadOrder.billing_address_id,company_id:selectedLeadOrder.company_id}}/>
            )}
            {isPreviewRemarksOpen === 'open_remark_preview' &&
                <Modal
                    isOpen={isPreviewRemarksOpen === 'open_remark_preview'}
                    onRequestClose={() => setIsPreviewRemarksOpen(false)}
                    className='Modal'
                >
                    <div className="container">
                        <div className='vendor-details'>
                            <h2>Full Description : </h2>
                            <div dangerouslySetInnerHTML={{__html: selectedLeadOrder.description}}></div>
                        </div>
                        <div className='buttons'>
                            <button className='close-button' onClick={() => setIsPreviewRemarksOpen(false)}>Close</button>
                        </div>
                    </div>
                </Modal>}
        </>
    );
};

export default OfflineOrderTable;
