import React, { useState, useEffect } from "react";
import { callUpdateDataWithLogFunction } from "../../../store/action/commonActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";

const EditCurriculumContent = ({ selectedCurriculum, initialData, onSave, onCancel }) => {
    const eventTypeId = useSelector((state) => state.curriculum.curriculumEventTypeId);
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        type: '',
        name: '',
        flipbook_code: '',
        video_duration_in_seconds: '',
        poster_url: '',
        ordering: '',
        description: '',
        url: ''
    });

    const [uploadedFile, setUploadedFile] = useState([]);
    const [errors, setErrors] = useState({});
    const { auth } = useAuth();

    useEffect(() => {
        if (initialData) {
            setFormData({
                type: initialData.type || '',
                name: initialData.name || '',
                flipbook_code: initialData.flipbook_code || '',
                video_duration_in_seconds: initialData.video_duration_in_seconds || '',
                poster_url: initialData.poster_url || '',
                ordering: initialData.ordering || '',
                description: initialData.description || '',
                url: initialData.url || ''
            });
        }
    }, [initialData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleImageUpload = (event) => {
        const files = event.target.files;
        setUploadedFile(Array.from(files));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = await validateForm(formData);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            const payload = { ...formData };
            try {
                // Update data API call
                await dispatch(callUpdateDataWithLogFunction({
                    tableName: "curriculum_content",
                    data: payload,
                    userId: auth?.id,
                    eventTypeId: eventTypeId,
                }));
                toast.success("Curriculum content updated successfully!");
                onUpdateSuccess?.(); // Optional callback for parent to refresh data
            } catch (error) {
                toast.error("Failed to update curriculum content.");
            }
        } else {
            toast.error("Please fix the errors before submitting.");
        }
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.type?.trim()) {
            errors.type = "Type is required";
        }

        if (data.type === "flipbook" && !data.flipbook_code?.trim()) {
            errors.flipbook_code = "Flipbook code is required";
        }

        if (!data.description?.trim()) {
            errors.description = "Description is required";
        }

        return errors;
    };

    return (
        <div className="grade-container">
            <form id="edit-curriculum-content" onSubmit={handleSubmit}>
                <div className="input-box">
                    <select
                        className="select"
                        name="type"
                        value={formData.type || ""}
                        onChange={handleChange}
                    >
                        <option value="">Please select File Type</option>
                        <option value="image">Image</option>
                        <option value="video">Video</option>
                        <option value="youtube">YouTube</option>
                        <option value="flipbook">Flipbook</option>
                        <option value="file">File</option>
                    </select>
                    {errors.type && <span className="error-message"> {errors.type}</span>}
                </div>
                <div className="vendor-details">
                    {formData.type === "flipbook" && (
                        <div className="input-box">
                            <span className="details">Enter Flipbook Code</span>
                            <input
                                name="flipbook_code"
                                type="text"
                                value={formData.flipbook_code || ""}
                                onChange={handleChange}
                                placeholder="Enter Flipbook code"
                            />
                            {errors.flipbook_code && (
                                <span className="error-message"> {errors.flipbook_code}</span>
                            )}
                        </div>
                    )}
                    <div className="input-box">
                        <span className="details">Enter Name</span>
                        <input
                            className="form-input"
                            name="name"
                            type="text"
                            value={formData.name || ""}
                            onChange={handleChange}
                            placeholder="Enter Name"
                        />
                        {errors.name && <span className="error-message"> {errors.name}</span>}
                    </div>
                    <div className="input-box">
                        <span className="details">Enter Description</span>
                        <textarea
                            className="text-area"
                            name="description"
                            value={formData.description || ""}
                            onChange={handleChange}
                            placeholder="Enter Description"
                        ></textarea>
                        {errors.description && (
                            <span className="error-message"> {errors.description}</span>
                        )}
                    </div>
                    <input
                        type="file"
                        multiple
                        accept="*"
                        name="url"
                        className="upload-input"
                        onChange={handleImageUpload}
                    />
                </div>
                <div className="buttons">
                    <div className="button">
                        <button className="add-curriculum-button" type="submit">
                            Update
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default EditCurriculumContent;
