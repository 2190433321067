import React, { useEffect, useState } from "react";
import {makeApiCall} from "../../../hooks/api/makeApiCall";

const LeaveLogPerUser = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch all user leave counts
    useEffect(() => {
        const fetchLeaveCounts = async () => {
            try {
                setLoading(true);
                const response = await makeApiCall("/gallery/get-leave-log-table");
                setUsers(response);
            } catch (err) {
                setError("Failed to load data.");
            } finally {
                setLoading(false);
            }
        };

        fetchLeaveCounts();
    }, []);

    // Fetch detailed leave data for a specific user
    const fetchUserDetails = async (userId) => {
        try {
            setLoading(true);
            // Filter the selected user from the users array
            const user = users.find((u) => u.user_id === userId);
            setSelectedUser(user); // Set the selected user details
            setModalData(user.day_date_array);
        } catch (err) {
            setError("Failed to load user details.");
        } finally {
            setLoading(false);
        }
    };

    const openModal = (user) => {
        setSelectedUser(user);
        fetchUserDetails(user.user_id);
    };

    const closeModal = () => {
        setSelectedUser(null);
        setModalData(null);
    };

    if (loading && !users.length) {
        return <div className="text-center text-gray-600">Loading...</div>;
    }

    if (error) {
        return <div className="text-center text-red-600">{error}</div>;
    }

    return (
        <div className="p-6">

            <div className="overflow-x-auto">
                <table className='products-table'>
                    <thead>
                    <tr className="bg-gray-100">
                        <th className="border border-gray-300 px-4 py-2">Sr. No.</th>
                        <th className="border border-gray-300 px-4 py-2">Name</th>
                        <th className="border border-gray-300 px-4 py-2">Total Present</th>
                        <th className="border border-gray-300 px-4 py-2">Total Absent</th>
                        <th className="border border-gray-300 px-4 py-2">Total Leaves</th>
                        {/*<th className="border border-gray-300 px-4 py-2">Total Holidays</th>*/}
                        <th className="border border-gray-300 px-4 py-2">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user,index) => (
                        <tr key={user.user_id}>
                            <td className="border border-gray-300 px-2 py-1">{index+1}</td>
                            <td className="border border-gray-300 px-2 py-1">{user.name}</td>
                            <td className="border border-gray-300 px-2 py-1">{user.total_present}</td>
                            <td className="border border-gray-300 px-2 py-1">{user.total_absent}</td>
                            <td className="border border-gray-300 px-2 py-1">{user.total_leaves}</td>
                            {/*<td className="border border-gray-300 px-2 py-1">{user.total_holidays}</td>*/}
                            <td className="border border-gray-300 px-2 py-1 text-center">
                                <button
                                    className="view-button"
                                    onClick={() => openModal(user)}
                                >
                                    View Details
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {selectedUser && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-2xl">
                        <h2 className="text-xl font-bold mb-4">
                            Leave Details: {selectedUser.name}
                        </h2>
                        {loading ? (
                            <div className="text-center text-gray-600">Loading details...</div>
                        ) : modalData ? (
                            <div className="overflow-y-auto max-h-96">
                                <table className="table-auto w-full border-collapse border border-gray-300">
                                    <thead>
                                    <tr className="bg-gray-100">
                                        <th className="border border-gray-300 px-4 py-2">Date</th>
                                        <th className="border border-gray-300 px-4 py-2">Day</th>
                                        <th className="border border-gray-300 px-4 py-2">Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {modalData.map((item, index) => (
                                        <tr key={index}>
                                            <td className="border border-gray-300 px-4 py-2">{item.date}</td>
                                            <td className="border border-gray-300 px-4 py-2">{item.day}</td>
                                            <td className="border border-gray-300 px-4 py-2">{item.status}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="text-center text-red-600">No data available.</div>
                        )}

                        <div className="text-right mt-4">
                            <button
                                className="add-button"
                                onClick={closeModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LeaveLogPerUser;
