import React, { useState,useEffect} from "react";
import Select from 'react-select';
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import PreLoader from "../../loader/PreLoader";
import swal from "sweetalert";
import EditUsers from "./EditUsers";
import {actionToDeleteUser, actionToGetUsersListApiCall} from "../../../store/action/usersActions";
import {actionToGetTagListDropdownApiCall,actionToAddTagToEmailIdApiCall} from "../../../store/action/commonActions";
import {capitalizeFirstLetter} from "../../../store/helper/commonHelper";
import useAuth from "../../../hooks/useAuth";
import {toast} from "react-toastify";
const UsersTable = React.memo(function (){
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(false);
     const [tagDropdown, setTagDropdown] = useState([]);
     const [selectedTags, setSelectTags] = useState([]);
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const usersData = useSelector((state) =>  state.users?.usersListData);
    const tagListDropDownData = useSelector((state) => state.common.tagListDropDownData);
    useEffectOnce(()=>{
        dispatch(actionToGetUsersListApiCall());
        dispatch(actionToGetTagListDropdownApiCall()); 
    })

    const deleteUser = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this User "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    let payload  = {id: row.id,created_by:auth?.id};
                    dispatch(actionToDeleteUser(payload)).then(() => {
                        dispatch(actionToGetUsersListApiCall())
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(usersData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === usersData.length);
    };
    // Handle multi-select change
   const handleMultiSelectChange = (selectedOptions) => {
    setSelectTags(selectedOptions || []);
  };
  // Handle Apply Click with Confirmation
    const handleApplyClick = () => {
      const selectedStatusLabels = selectedTags.map((status) => status.label).join(', ');
      const confirmationMessage = `
        Are you sure you want to apply the following tags:
        ${selectedStatusLabels}
        To rows: ${JSON.stringify(selectedRows)}?
      `;
      swal({
        title: "Are you sure?",
        text: confirmationMessage,
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then(async (success) => {
            if (success) {
                selectedTags?.map(val=>{
                    // API call to add tag to email id
                    dispatch(actionToAddTagToEmailIdApiCall({
                        "columnName":"user_id",
                        "tagId":val?.value,
                        "idArray":selectedRows
                    }))
                });
                  toast.success(`Tags successfully applied: ${selectedStatusLabels} to rows: ${JSON.stringify(selectedRows)}!`); // Success notification
                  // Removeing the selected rows and selected tags
                  setSelectedRows([]);
                  setSelectTags([]);
                  dispatch(actionToGetUsersListApiCall());
            }
        });
    
    };
  
    useEffect(() => {
            let tagArray = [];
            tagListDropDownData?.map(val=>{
                    tagArray.push({value:val.id,label:val.name});
            });
                setTagDropdown(tagArray);
        },[tagListDropDownData]);
    
    return(
        <>
            <div className='table-container'>
            {
                usersData === 'loading' ?
                    <PreLoader/>
                    :

        <div className="table">
            <div className="user-row-header">
                <div className="seven-column-cell">
                    <input className='checkbox' type="checkbox"
                           checked={selectAll}
                           onChange={handleSelectAll}/>
                    Name
                </div>
                <div className="seven-column-cell">Company</div>
                <div className="seven-column-cell">Role</div>
                <div className="seven-column-cell">Email</div>
                <div className="seven-column-cell">Mobile</div>
                <div className="seven-column-cell">Gender</div>
                <div className="seven-column-cell">Action</div>
            </div>
            <Virtuoso style={{ height: '600px' }} totalCount={usersData.length} itemContent={index =>
                <div className="table-row">
                    <div className="four-column-cell">
                        <input className='checkbox' type="checkbox"
                               checked={selectedRows.includes(usersData[index]?.id)}
                               onChange={() => handleSelectRow(usersData[index]?.id)}/>
                        {usersData[index]?.name}
                    </div>
                    <div className="four-column-cell">{usersData[index]?.company_name}</div>
                    <div className="four-column-cell">{usersData[index]?.role_name}</div>
                    <div className="four-column-cell">{usersData[index]?.email}</div>
                    <div className="four-column-cell">{usersData[index]?.mobile}</div>
                    <div className="four-column-cell">{capitalizeFirstLetter(usersData[index]?.gender)}</div>
                    <div className="four-column-cell">
                        <div className='action-components'>
                            <div className='action-buttons'>
                                <button className='action-button' onClick={() => {setIsEditOpen(true); setSelectedIndex(index)}} >
                                    <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                </button>
                            </div>
                            <div className='action-buttons'>
                                <button onClick={()=>deleteUser(usersData[index])} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            } />
        </div>
            }
            <EditUsers isOpen={isEditOpen} selectedUser={usersData[selectedIndex]} onRequestClose={() => setIsEditOpen(false)} />
                {/* Show Change Status multi-select dropdown at the bottom */}
      {selectedRows.length > 0 && (
        <div
          style={{
            marginTop: '20px',
            padding: '10px',
            border: '1px solid #ccc',
            backgroundColor: '#f9f9f9',
            textAlign: 'center',
          }}
        >
          <strong>Apply Tags:</strong>
          <div style={{ marginTop: '10px' }}>
            <Select
              isMulti
              options={tagDropdown}
              value={selectedTags}
              onChange={handleMultiSelectChange}
              placeholder="Select statuses"
              styles={{
                container: (base) => ({ ...base, minWidth: '200px', margin: '0 auto' }),
              }}
            />
            <button
              style={{ margin: '10px', padding: '5px 10px' }}
              onClick={handleApplyClick}
              disabled={selectedTags.length === 0}
            >
              Apply
            </button>
          </div>
          <div>
            <small>Selected rows: {JSON.stringify(selectedRows)}</small>
          </div>
        </div>
      )}
            </div>
        </>
    )
})

export default UsersTable;
