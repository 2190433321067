import React, {useState,  useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareEnvelope, faTag} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {Link, useParams} from "react-router-dom";
import {subDomainUrl} from "../../hooks/config";
import TagsForm from "./tags/TagsForm";
import TagsTable from "./tags/TagsTable";
import FromEmailForm from "./from-emails/FromEmailForm";
import FromEmailTable from "./from-emails/FromEmailTable";
import TemplateForm from "./email-templates/TemplateForm";
import TemplateTable from "./email-templates/TemplateTable";
import EmailModal from "./compose-email/email-modal";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {makeApiCall} from "../../hooks/api/makeApiCall";
import {SCHOOL_LIST} from "../../store/constant/salesConstant";
import {useDispatch} from "react-redux";
import {FROM_EMAIL_LIST} from "../../store/constant/marketingConstant";


const MarketingComponent = React.memo(function () {
    const dispatch = useDispatch();
    let {tabName} = useParams();
    const [addModalName, setAddModalName] = useState(false);
    const [fetchDataTable, setFetchDataTable] = useState(false);
    const [activeTab, setActiveTab] = useState('tags');

    const handleExternalFetch = (res,affetcedTab) => {
        if (affetcedTab?.includes(activeTab)) {
            setFetchDataTable(true);  // Example call to fetchData with parameters
        }
    };
     // Example "From" email addresses
  const fromAddresses = ['sender1@example.com', 'sender2@example.com'];
    const getTabTable =() =>{
        switch (activeTab){
            case 'tags':
                return <TagsTable fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
            case 'from-emails':
                return <FromEmailTable fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
             case 'email-templates':
                return <TemplateTable fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
            default:
                return <TagsTable fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
        }
    }
    useEffect(() => {
        setActiveTab(tabName);
    }, [tabName]);
    useEffectOnce(()=>{
        makeApiCall(`marketing/from-email-list-dropdown`,FROM_EMAIL_LIST,dispatch);
    })
    return (
        <>
            <section className='section'>
                <div className='sub-header'>
                    <div className='subheader-box'>
                        <div className="tab-container">
                            <div className="tab">
                                <Link to={subDomainUrl+'/marketing/tags'} className='tab'><button
                                    className={`tab ${activeTab === 'tags' ? 'active' : ''}`}>
                                   Tags
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/marketing/from-emails'} className='tab'><button
                                    className={`tab ${activeTab === 'from-emails' ? 'active' : ''}`}>
                                   From Emails
                                </button>
                                </Link>
                                <Link to={subDomainUrl+'/marketing/email-templates'} className='tab'><button
                                    className={`tab ${activeTab === 'email-templates' ? 'active' : ''}`}>
                                   Email Templates
                                </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='subheader-box'>
                        <div className='subheader-components'>
                            <Tippy content='Add Tag'>
                                <button  className='common-button' onClick={() => setAddModalName('add_tags')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faTag} />
                                </button>
                            </Tippy>
                            <Tippy content='Add From Email'>
                                <button  className='common-button' onClick={() => setAddModalName('add_from_email')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faSquareEnvelope} />
                                </button>
                            </Tippy>
                            <Tippy content='Add Email Template'>
                                <button  className='common-button' onClick={() => setAddModalName('add_email_template')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faSquareEnvelope} />
                                </button>
                            </Tippy>
                            <Tippy content='Compose Email'>
                                <button  className='common-button' onClick={() => setAddModalName('compose_email')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faSquareEnvelope} />
                                </button>
                            </Tippy>
                        </div>

                    </div>
                </div>
                <div className="tab-content">
                    {getTabTable()}
                </div>
                <TagsForm
                    handleExternalFetch={handleExternalFetch}
                    isOpen={addModalName === 'add_tags'}
                    onRequestClose={() => setAddModalName(false)}
                    isEditMode={false}
                />
                <FromEmailForm
                    handleExternalFetch={handleExternalFetch}
                    isOpen={addModalName === 'add_from_email'}
                    onRequestClose={() => setAddModalName(false)}
                    isEditMode={false}
                />
                <TemplateForm
                    handleExternalFetch={handleExternalFetch}
                    isOpen={addModalName === 'add_email_template'}
                    onRequestClose={() => setAddModalName(false)}
                    isEditMode={false}
                />
                <EmailModal
                 isOpen={addModalName === 'compose_email'}
                 onClose={() => setAddModalName(false)}
                 fromAddresses={fromAddresses} 
                 />
            </section>
        </>
    )
});

export default MarketingComponent;
