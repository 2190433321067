import React, {useState,  useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBattery,
    faFileAlt

} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import {Link, useParams} from "react-router-dom";
import {subDomainUrl} from "../../hooks/config";
import DailyReportForm from "./daily-report/DailyReportForm";
import DailyReportTable from "./daily-report/DailyReportTable";
import AuthorizedComponent from "../auth/AuthorizedComponent";
import AttendanceForm from "./daily-report/AttendanceForm";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {actionToGetUsersListApiCall} from "../../store/action/usersActions";
import {useDispatch} from "react-redux";
import HolidayList from "./holiday/HolidayList";
import LeaveLogPerUser from "./daily-report/LeaveLogPerUser";


const DailyReportComponent = React.memo(function () {
    const dispatch = useDispatch();
    let {tabName} = useParams();
    const [addModalName, setAddModalName] = useState(false);
    const [fetchDataTable, setFetchDataTable] = useState(false);
    const [activeTab, setActiveTab] = useState('daily-report');

    const handleExternalFetch = (res,affetcedTab) => {
        if (affetcedTab?.includes(activeTab)) {
            setFetchDataTable(true);  // Example call to fetchData with parameters
        }
    };
    const getTabTable =() =>{
        switch (activeTab){
            case 'daily-report':
                return <DailyReportTable fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
           case 'holiday':
                return <HolidayList />;
           case 'leave-log-user':
                return <LeaveLogPerUser />;
            default:
                return <DailyReportTable fetchDataTable={fetchDataTable} setFetchDataTable={setFetchDataTable}/>;
        }
    }
    useEffect(() => {
        setActiveTab(tabName);
    }, [tabName]);

    useEffectOnce(()=>{
        dispatch(actionToGetUsersListApiCall({not_customer:true}));
    })
    return (
        <>
            <section className='section'>
                <div className='sub-header'>
                    <div className='subheader-box'>
                        <div className="tab-container">
                            <div className="tab">
                                <Link to={subDomainUrl+'/daily-report/daily-report'} className='tab'><button
                                    className={`tab ${activeTab === 'daily-report' ? 'active' : ''}`}>
                                   Daily Report
                                </button>
                                </Link> <Link to={subDomainUrl+'/daily-report/holiday'} className='tab'><button
                                    className={`tab ${activeTab === 'holiday' ? 'active' : ''}`}>
                                   Holiday
                                </button>
                                </Link>
                                <AuthorizedComponent allowedRoles={[1,9]}
                                                     AuthComponent={<>   <Link to={subDomainUrl+'/daily-report/leave-log-user'} className='tab'><button
                                                         className={`tab ${activeTab === 'leave-log-user' ? 'active' : ''}`}>
                                                         Leave Log
                                                     </button>
                                                     </Link>
                                                     </>}
                                                     Component={''}/>

                            </div>
                        </div>
                    </div>
                    <div className='subheader-box'>
                        <div className='subheader-components'>
                            <Tippy content='Add Daily Report'>
                                <button  className='common-button' onClick={() => setAddModalName('add_daily_report')}>
                                    <FontAwesomeIcon className='filter-icon' icon={faFileAlt} />
                                </button>
                            </Tippy>
                            <AuthorizedComponent allowedRoles={[1,2,9]}
                                                 AuthComponent={<>   <Tippy content='Add Absent/Leave'>
                                                     <button  className='common-button' onClick={() => setAddModalName('add_absent_leave')}>
                                                         <FontAwesomeIcon className='filter-icon' icon={faBattery} />
                                                     </button>
                                                 </Tippy>
                                                 </>}
                                                 Component={''}/>

                        </div>

                    </div>
                </div>
                <div className="tab-content">
                    {getTabTable()}
                </div>
                <DailyReportForm
                    handleExternalFetch={handleExternalFetch}
                    isOpen={addModalName === 'add_daily_report'}
                    onRequestClose={() => setAddModalName(false)}
                    isEditMode={false}
                    selectedData={{}}
                />
                <AttendanceForm
                    handleExternalFetch={handleExternalFetch}
                    isOpen={addModalName === 'add_absent_leave'}
                    onRequestClose={() => setAddModalName(false)}
                    isEditMode={false}
                />
            </section>
        </>
    )
});

export default DailyReportComponent;
