import { combineReducers } from "redux";
import {webSettingReducer} from "./reducer/webSettingReducer.js";
import {inventoryReducer} from "./reducer/inventoryReducer.js";
import {usersReducer} from "./reducer/usersReducer";
import {curriculumReducer} from "./reducer/curriculumReducer";
import {salesReducer} from "./reducer/salesReducer";
import {commonReducer} from "./reducer/commonReducer";
import {galleryReducer} from "./reducer/galleryReducer";
import {marketingReducer} from "./reducer/marketingReducer";




const rootReducer = combineReducers({
    webSetting: webSettingReducer,
    inventory: inventoryReducer,
    users: usersReducer,
    curriculum: curriculumReducer,
    sales:salesReducer,
    common:commonReducer,
    gallery:galleryReducer,
    marketing:marketingReducer,
});

export default rootReducer
