// components/SchoolContactPersonTable.js
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchData } from '../../../hooks/useFetchData';
import { callSoftDeleteDataWithLogFunction } from '../../../store/action/commonActions';
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import useAuth from "../../../hooks/useAuth";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import {indianDateTimeFormat} from "../../../store/utility/DatatableUtility";
import TagsForm from "./TagsForm";

const TagsTable = ({setFetchDataTable,fetchDataTable}) => {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) => state.marketing.marketingEventTypeId);

    const { data:tagsData, fetchData,settings } = useFetchData(`marketing/get-tags`, [], {});
    const [isEditTagOpen, setIsEditTagOpen] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const [columns, setColumns] = useState([]);
    const ROLES = useSelector((state) =>  state.webSetting.roleList);

    // Define default columns
    const defaultColumns = [
        { name: 'Sr No', selector: row => row.id, selector_field:'id',
            cell: (row,index) => (
                <>
                    {index+1}
                </>
            ),width:'60px'  },
        { name: 'Name', selector: row => row.name, selector_field:'name',sortable: true,width:'150px'  },
        { name: 'Created Date', selector: row => row.created_at && indianDateTimeFormat(row.created_at), selector_field:'created_at',sortable: true },
        { name: 'Actions', cell: (row) => (
                <div className='action-components'>
                    <TableEditActions
                        tooltip={"Edit"}
                        row={row}
                        onEdit={() => { setSelectedTag(row); setIsEditTagOpen('edit'); }}
                    />
                    <TableDeleteActions
                        tooltip={"Delete"}
                        row={row}
                        onDelete={(row) => dispatch(callSoftDeleteDataWithLogFunction({ tableName: 'tags', id: row.id, userId: auth?.id, eventTypeId })).then(fetchData)}
                    />
                </div>
            ), width: '150px' }
    ];

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable]);
    // Update columns based on conditions
    useEffect(() => {
        let newColumns = [...defaultColumns];
        if ([ROLES.SuperAdmin,ROLES.Admin].includes(auth.role)) {
            newColumns.splice(1,0, { name: 'User', selector: row => row.user, selector_field:'user',sortable: true,width:'150px' });
         }

        setColumns(newColumns);
    }, [auth]);
    return (
        <>
            <DataTableLayout columns={columns} data={tagsData.data} dataTableSetting={settings} fetchData={fetchData} totalRows={tagsData?.total} />
            {isEditTagOpen === 'edit' && (
                <TagsForm
                    handleExternalFetch={fetchData}
                    isOpen={isEditTagOpen==='edit'}
                    onRequestClose={() => setIsEditTagOpen(false)}
                    selectedData={selectedTag}
                    isEditMode={true}
                />
            )}
        </>
    );
};

export default TagsTable;
