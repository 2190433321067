import {
    COMPANY_DATA_LIST, COMPONENT_DATA_LIST,
    MENU_DATA_LIST, MENU_DETAIL,
    SEO_META_DATA_LIST,
    SEO_REFERENCE_HTML,
    WEB_SETTING_CONTENT,
    WEB_SETTING_SECTION,
    WEBSITE_CONTENT
} from "../constant/webSettingConstant";
import {ROLE_LIST} from "../constant";

const initialState = {
    contentData:[],
    contentJsonData:[],
    seoMetaData:[],
    seoReferenceHtml:[],
    menuDataList:[],
    menuDetail:[],
    companyDataList:[],
    componentDataList:[],
    websiteSectionData:[],
    roleList:{SuperAdmin:1,Admin: 2, SalesTeam: 3, WebsiteEditor: 4, InventoryManager: 5, Trainer: 6,Customer: 7,GemAdmin:8,Accountant:9},
    webSettingEventTypeId:3,
}
export  const webSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case WEB_SETTING_CONTENT:
            return { ...state, contentData: action.payload };
        case WEB_SETTING_SECTION:
            return { ...state, websiteSectionData: action.payload };
        case WEBSITE_CONTENT:
            return { ...state, contentJsonData: action.payload };
        case SEO_META_DATA_LIST:
            return { ...state, seoMetaData: action.payload };
        case SEO_REFERENCE_HTML:
            return { ...state, seoReferenceHtml: action.payload };
        case MENU_DATA_LIST:
            return { ...state, menuDataList: action.payload };
        case MENU_DETAIL:
            return { ...state, menuDetail: action.payload };
        case COMPANY_DATA_LIST:
            return { ...state, companyDataList: action.payload };
        case COMPONENT_DATA_LIST:
            return { ...state, componentDataList: action.payload };
        case ROLE_LIST:
            return { ...state, roleList: action.payload };

        default:
            return state
    }
}