import React, { useState,useRef } from 'react';
import Modal from 'react-modal';
import './EmailModal.css'; // Optional for styling
import TinyMceEditor from '../../Layout/Form/TinyMceEditor';


const EmailModal = ({ isOpen, onClose, fromAddresses }) => {
  const templates = [
    { id: 1, name: 'Welcome Email', content: 'Hello [Name],\n\nWelcome to our service!' },
    { id: 2, name: 'Follow-Up Email', content: 'Hi [Name],\n\nJust checking in to see how you’re doing.' },
    { id: 3, name: 'Thank You Email', content: 'Dear [Name],\n\nThank you for choosing our company!' },
  ];
 const editorRef = useRef(null);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [recipient, setRecipient] = useState('');
  const [cc, setCc] = useState('');
  const [bcc, setBcc] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [fromEmail, setFromEmail] = useState('');

  const handleTemplateChange = (event) => {
    const templateId = parseInt(event.target.value, 10);
    const selected = templates.find((template) => template.id === templateId);
    setSelectedTemplate(templateId);
    setEmailBody(selected ? selected.content : '');
  };

  const handleAttachmentChange = (event) => {
    const files = Array.from(event.target.files);
    setAttachments(files);
  };

  const handleSend = () => {
    if (!recipient || !emailSubject || !emailBody || !fromEmail) {
      alert('Please fill in all required fields before sending.');
      return;
    }
    alert(`Email sent to ${recipient} with subject "${emailSubject}" from ${fromEmail}`);
    onClose(); // Close the modal after sending
  };
  const changeOnEditor = (e)=>{
  }
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Compose Email" className="modal-content" overlayClassName="modal-overlay">
      <h2>Compose Email</h2>

      {/* From Email Dropdown */}
      <div className="form-group">
        <label>From</label>
        <select
          value={fromEmail}
          onChange={(e) => setFromEmail(e.target.value)}
        >
          <option value="">Select sender address</option>
          {fromAddresses.map((address) => (
            <option key={address} value={address}>
              {address}
            </option>
          ))}
        </select>
      </div>

      {/* Recipient */}
      <div className="form-group">
        <label>Recipient</label>
        <input
          type="email"
          placeholder="Enter recipient email"
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
        />
      </div>

      {/* CC */}
      <div className="form-group">
        <label>CC</label>
        <input
          type="email"
          placeholder="Enter CC email addresses"
          value={cc}
          onChange={(e) => setCc(e.target.value)}
        />
      </div>

      {/* BCC */}
      <div className="form-group">
        <label>BCC</label>
        <input
          type="email"
          placeholder="Enter BCC email addresses"
          value={bcc}
          onChange={(e) => setBcc(e.target.value)}
        />
      </div>

      {/* Subject */}
      <div className="form-group">
        <label>Subject</label>
        <input
          type="text"
          placeholder="Enter email subject"
          value={emailSubject}
          onChange={(e) => setEmailSubject(e.target.value)}
        />
      </div>

      {/* Template Selector */}
      <div className="form-group">
        <label>Template</label>
        <select value={selectedTemplate} onChange={handleTemplateChange}>
          <option value="">Select a template</option>
          {templates.map((template) => (
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          ))}
        </select>
      </div>

      {/* Email Body */}
      <div className="form-group">
        {/* <label>Email Body</label> */}
        {/* <textarea
          rows="6"
          placeholder="Enter email content"
          value={emailBody}
          onChange={(e) => setEmailBody(e.target.value)}
        /> */}
        <TinyMceEditor 
                        label={"Email Body"} 
                        name="html_content" 
                        value={''} 
                        onChange={changeOnEditor} 
                        error={''} 
                        editorRef={editorRef}
                    />
      </div>

      {/* Attachments */}
      <div className="form-group">
        <label>Attachments</label>
        <input
          type="file"
          multiple
          onChange={handleAttachmentChange}
        />
        <div>
          {attachments.length > 0 && (
            <ul>
              {attachments.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Modal Actions */}
      <div className="modal-actions">
        <button className="btn btn-primary" onClick={handleSend}>
          Send
        </button>
        <button className="btn btn-secondary" onClick={onClose}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default EmailModal;
