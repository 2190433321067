import {FROM_EMAIL_LIST} from "../constant/marketingConstant";

const initialState = {
    marketingEventTypeId:3,
    fromEmailList:[]
}
export  const marketingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FROM_EMAIL_LIST:
            return { ...state, fromEmailList: action.payload };

        default:
            return state
    }
}