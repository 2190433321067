import {
    SUB_CATEGORY_LIST,
    CATEGORY_DATA,
    BRAND_DATA,
    PRODUCT_DATA,
    PRODUCT_IMAGE_DATA,
    PRODUCT_CURRICULUM_DATA,
    PRODUCT_CURRICULUM_IMAGES_DATA,
    DISCOUNT_COUPON_LIST_DATA,
    PRODUCT_TOPICS_DATA,
    PRODUCT_GRADES_DATA,
    PRODUCT_SUBJECTS_DATA,
    PRODUCT_TABLE_DATA,
    ORDERS_DETAIL_DATA,
    PRODUCT_DROPDOWN_LIST_DATA,
    PRODUCT_COMPONENT_DATA,
    VENDOR_TRANSPORTER_DROPDOWN_LIST_DATA,
    PURCHASE_ITEM_LIST_DATA,
    PACKAGING_ITEMS_DROPDOWN_LIST_DATA, ORDERS_PROCESSING_DETAIL_DATA
} from "../constant/inventoryConstant";

const initialState = {
    categoryData:[],
    subCategoryData:[],
    productData:[],
    productTableData:[],
    productComponentData:[],
    orderTableData:[],
    orderDetailData:[],
    productTopicsData:[],
    productGradesData:[],
    productSubjectsData:[],
    productImageData:[],
    productCurriculumData:[],
    productCurriculumImageData:[],
    brandData:[],
    urlSlugData:[],
    updateUrlSlugFormData:[],
    discountCouponListData:[],
    productDropdownListData:[],
    vendorTransporterDropdownListData:[],
    packagingItemDropdownListData:[],
    orderProcessingDetailListData:[],
    inventoryEventTypeId:2,
    purchaseOrderDetailData:[]
}
export  const inventoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORY_DATA:
            return { ...state, categoryData: action.payload };
        case SUB_CATEGORY_LIST:
            return { ...state, subCategoryData: action.payload };
        case PRODUCT_DATA:
            return { ...state, productData: action.payload };
        case PRODUCT_TABLE_DATA:
            return { ...state, productTableData: action.payload };
        case PRODUCT_COMPONENT_DATA:
            return { ...state, productComponentData: action.payload };
       case ORDERS_DETAIL_DATA:
            return { ...state, orderDetailData: action.payload };
        case BRAND_DATA:
            return { ...state, brandData: action.payload };
        case PRODUCT_IMAGE_DATA:
            return { ...state, productImageData:action.payload };
        case PRODUCT_CURRICULUM_DATA:
            return { ...state, productCurriculumData:action.payload };
        case PRODUCT_GRADES_DATA:
            return { ...state, productGradesData:action.payload };
        case PRODUCT_SUBJECTS_DATA:
            return { ...state, productSubjectsData:action.payload };
        case PRODUCT_TOPICS_DATA:
            return { ...state, productTopicsData:action.payload };
        case PRODUCT_CURRICULUM_IMAGES_DATA:
            return { ...state, productCurriculumImageData:action.payload };
        case DISCOUNT_COUPON_LIST_DATA:
            return { ...state, discountCouponListData:action.payload };
        case PRODUCT_DROPDOWN_LIST_DATA:
            return { ...state, productDropdownListData:action.payload };
        case VENDOR_TRANSPORTER_DROPDOWN_LIST_DATA:
            return { ...state, vendorTransporterDropdownListData:action.payload };
        case PACKAGING_ITEMS_DROPDOWN_LIST_DATA:
            return { ...state, packagingItemDropdownListData:action.payload };
        case ORDERS_PROCESSING_DETAIL_DATA:
            return { ...state, orderProcessingDetailListData:action.payload };
        case PURCHASE_ITEM_LIST_DATA:
            return { ...state, purchaseOrderDetailData:action.payload };
        default:
            return state
    }
}