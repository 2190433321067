import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import SalesComponent from "../Components/Sales/SalesComponent";
import MarketingComponent from "../Components/marketing/MarketingComponent";
export default function MarketingPage

    () {
    return (
        <>
            <Routes>
                <Route path="" exact element={<Navigate to="tags" replace />} />
                <Route path="/:tabName" element={<MarketingComponent />} />
            </Routes>
        </>
    )
}