import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {Virtuoso} from "react-virtuoso";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBroom, faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import EditCategory from "./EditCategory";
import PreLoader from "../../loader/PreLoader";
import swal from "sweetalert";
import {
    actionToDeleteCategory,
    actionToGetCategoriesApiCall,
    uploadFileOnAws
} from "../../../store/action/inventoryActions";
import useAuth from "../../../hooks/useAuth";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import AuthorizedComponent from "../../auth/AuthorizedComponent";
import {callUpdateDataWithLogFunction} from "../../../store/action/commonActions";
import {actionToGetMenuListApiCall} from "../../../store/action/webSettingActions";
import CompanyPipeline from "../../WebSetting/website_settings/company/CompanyPipeline";
export const CategoryTable = () => {
    const [isEditCategoryOpen, setIsEditCategoryOpen] = useState(false);
    const dispatch = useDispatch();
    const categoryData = useSelector((state) =>  state.inventory?.categoryData);
    const [categoryListData, setCategoryListData] = useState([]);
    const ROLES = useSelector((state) =>  state.webSetting.roleList);
    const [selectedIndex, setSelectedIndex] = useState(false);
    const {auth} = useAuth();
    const hiddenFileInput = useRef(null);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const eventTypeId = useSelector((state) =>  state.inventory.inventoryEventTypeId);
    const className = allowedRoles?.includes(auth?.role) ? 'five-column-cell' :'three-column-cell';
    const [source,setSource]=useState(auth?.source);

    const changeCompany = (val)=>{
        setSource(val.id);
    }

    const categoryFilterFunction = () =>{
        let categoryList=[]
        categoryData !== 'loading' && categoryData?.map(categoryArray=>{
            if(categoryArray.source ==source){
                categoryList.push(categoryArray);
            }
        })
        setCategoryListData(categoryList);
    }
    useEffect(() => {
        if(source){
            categoryFilterFunction();
        }
    }, [source,categoryData]);
    useEffectOnce(()=>{
        dispatch(actionToGetCategoriesApiCall());
    })

    const handlePhotoClick = (index) => {
        setSelectedIndex(index);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        const fileExtension = fileUploaded.name.split('.').pop();
        let data =categoryListData[selectedIndex];
        let payload = {filename:'category/'+data.name?.toLowerCase()+'.'+fileExtension,showOnWebsite:data.company_name,photo:fileUploaded,type:fileUploaded.type};
        data.photo ? payload['deletedFileName'] = data.company_name.toLowerCase() ==='stemcity'?
            data.photo?.replace('https://stemcity.s3.ap-southeast-2.amazonaws.com/','') : data.photo?.replace('https://shikshak-solutions.s3.eu-north-1.amazonaws.com/','')  :'';
        let url = await dispatch(uploadFileOnAws(payload));
        dispatch(callUpdateDataWithLogFunction({tableName:'categories',data: {photo:url+'?v='+Math.floor(1000 + Math.random() * 9000)},id:data?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            dispatch(actionToGetMenuListApiCall());
        })
    };
    const deleteCategory = (row) => {
        swal({
            title: "Are you sure?",
            text: "You want to delete this Category "+row?.name,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (success) => {
                if (success) {
                    let payload  = {id: row.id,created_by:auth?.id};
                    dispatch(actionToDeleteCategory(payload)).then(() => {
                        dispatch(actionToGetCategoriesApiCall())
                    })
                }
            });

    };
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedRows([]);
        } else {
            setSelectedRows(categoryListData.map(item => item.id));
        }
        setSelectAll(!selectAll);
    };

    const handleSelectRow = (id) => {
        const newSelectedRows = selectedRows.includes(id)
            ? selectedRows.filter(rowId => rowId !== id)
            : [...selectedRows, id];

        setSelectedRows(newSelectedRows);
        setSelectAll(newSelectedRows.length === categoryListData.length);
    };
    return(
        <>
            {/*<div className='flex justify-end'>*/}
            {/*    <Tippy content='Clean Cache'>*/}
            {/*        <button  className='submit-button mb-2'>*/}
            {/*            <FontAwesomeIcon icon={faBroom} />*/}
            {/*        </button>*/}
            {/*    </Tippy></div>*/}
            {
                categoryData === 'loading' ?
                    <PreLoader/>
                    :
                    <>
                        <CompanyPipeline source={source} changeCompany={changeCompany}/>
                        <div className="table">
                            <div className="row-header">
                                <div className={className}>
                                    <input className='checkbox' type="checkbox"
                                           checked={selectAll}
                                           onChange={handleSelectAll}/>
                                    Category
                                </div>
                                <div className={className}>Slug</div>
                                <AuthorizedComponent allowedRoles={allowedRoles}
                                                     AuthComponent={<><div className={className}>Company</div><div className={className}>Icon</div></>}
                                                     Component={''}/>
                                <div className={className}>Action</div>
                            </div>
                            <Virtuoso style={{ height: '600px' }} totalCount={categoryListData.length} itemContent={index =>
                                <div className="table-row">
                                    <div className={className}>
                                        <input className='checkbox' type="checkbox"
                                               checked={selectedRows.includes(categoryListData[index]?.id)}
                                               onChange={() => handleSelectRow(categoryListData[index]?.id)}/>
                                        {categoryListData[index]?.name}
                                    </div>
                                    <div className={className}>{categoryListData[index]?.slug}</div>

                                    <AuthorizedComponent allowedRoles={allowedRoles}
                                                         AuthComponent={<><div className={className}>{categoryListData[index]?.company_name}</div>
                                                             <div className={className} onClick={()=>handlePhotoClick(index)}>
                                                                 <img src={categoryListData[index]?.photo} height="35" alt={categoryListData[index]?.name} />
                                                             </div>
                                                         </>} Component={''}/>
                                    <div className={className}>
                                        <div className='action-components'>
                                            <div className='action-buttons'>
                                                <Tippy content='Edit Category'>
                                                    <button className='action-button' onClick={() => {setIsEditCategoryOpen(true); setSelectedIndex(index);}} >
                                                        <FontAwesomeIcon className='action-icon' icon={faPenToSquare} />
                                                    </button></Tippy>
                                            </div>
                                            <div className='action-buttons'>
                                                <Tippy content='Delete Category'>
                                                    <button onClick={()=>deleteCategory(categoryListData[index])} className='action-button'>
                                                        <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                                    </button></Tippy>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            } />
                        </div>
                    </>

            }
            <EditCategory isOpen={isEditCategoryOpen} selectedCategory={categoryListData[selectedIndex]} onRequestClose={() => {setIsEditCategoryOpen(false); setSelectedIndex(false);}} />
            <input type="file" onChange={handleMainPhotoUpload} ref={hiddenFileInput} style={{display: 'none'}} />
        </>
    )
}
