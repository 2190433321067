import {CITY_LIST, DESIGNATION_LIST, FORM_AUTH_RULE, STATE_LIST, ZIP_CODE_LIST,TAG_LIST_DROP_DOWN} from "../constant";

const initialState = {
    stateList:[],
    cityList:[],
    zipCodeList:[],
    designationList:[],
    deleteSettingRule:{'school':{allowedRoles:[]}},
    tagListDropDownData:[]
}
export  const commonReducer = (state = initialState, action) => {
    switch (action.type) {
       case STATE_LIST:
            return { ...state, stateList: action.payload };
        case CITY_LIST:
            return { ...state, cityList: action.payload };
        case ZIP_CODE_LIST:
            return { ...state, zipCodeList: action.payload };
        case FORM_AUTH_RULE:
            return { ...state, formAuthRule: action.payload };
        case DESIGNATION_LIST:
            return { ...state, designationList: action.payload };
        case TAG_LIST_DROP_DOWN:
                return { ...state, tagListDropDownData: action.payload };
        default:
            return state
    }
}
