import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import Select from "react-select";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import { actionToGetSubjectsApiCall} from "../../../store/action/curriculumAction";
import {callDeleteDataWithLogFunction, callInsertDataWithLogFunction} from "../../../store/action/commonActions";
import useAuth from "../../../hooks/useAuth";
import {toast} from "react-toastify";

const AddGradeSubject = (props) => {
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(true);
    const {auth} = useAuth();
    const [errors, setErrors] = useState({});
    const eventTypeId = useSelector((state) =>  state.curriculum.curriculumEventTypeId);
    const subjectsListData = useSelector((state) =>  state.curriculum.subjectsListData);
    const [subjectDropdown, setSubjectDropdown] = useState([]);
    const selectedData = props.selectedData;
    const [selectedGradeSubject,setSelectedGradeSubject] = useState([]);
    const [formData, setFormData] = useState({  });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...formData,
            [name]: value
        };
        setFormData(updatedFormData);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formValue = new FormData(e.target);
        const obj = Object.fromEntries(formValue.entries());
        const newErrors = await validateForm(obj);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(callInsertDataWithLogFunction({
                tableName: 'grade_subject',
                data: { grade_id: selectedData.id, subject_id: obj['subject_id'] },
                userId: auth?.id,
                eventTypeId: eventTypeId
            })).then(res => {
                if (res?.success === 1) {
                    dispatch(actionToGetSubjectsApiCall());
                    resetForm();
                    toast.success('Subject added successfully!'); // Success notification
                } else {
                    toast.error('Failed to add subject.'); // Failure notification
                }
            });
        } else {
            toast.error('Please correct the errors in the form.'); // Validation error notification
        }
    };
    const resetForm = () => {
        document.getElementById('grade-subject')?.reset();
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.subject_id.trim()) {
            errors.subject_id = 'Subject is required';
        }

        return errors;
    };
    const deleteGradeSubject = (id) =>{
        swal({
            title: "Are you sure?",
            text: "You want to delete this grade subject ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (success) => {
                if (success) {
                    dispatch(callDeleteDataWithLogFunction({tableName:'grade_subject',id:id,userId:auth?.id,eventTypeId})).then(() => {
                        dispatch(actionToGetSubjectsApiCall())
                    })
                }
            });

    }
    useEffect(() => {
        let subjectArray = [];
        setLoading(true);
        let gradeArray = [];
        subjectsListData?.map(val=>{
            if(val.id){
                subjectArray.push({value:val.id,label:val.name});
                if(val.grade){
                    val.grade.map((grade)=>{
                        if(grade.id === selectedData?.id)
                            gradeArray.push({id:grade.id,name:val.name});
                    })
                }
            }
        })
        setSubjectDropdown(subjectArray);
        setSelectedGradeSubject(gradeArray);
        setTimeout(()=>{
            setLoading(false);
        },1000)
    }, [subjectsListData,selectedData]);
    useEffectOnce(()=>{
        dispatch(actionToGetSubjectsApiCall())
    })

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            height: '50px',
            width: '95%',
            borderRadius: '5px',
            border: state.isFocused ? '1px solid #efdda8' : '1px solid #ccc',
            paddingLeft: '5px',
            fontSize: '13px',
            boxShadow: 'none',
            transition: 'all 0.3s ease',
            '&:hover': {
                borderColor: '#efdda8'
            },

        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '5px',
            width: '95%',
            marginTop: '2px',
            zIndex: 2,
            overflowY: 'auto',
            maxHeight: '150px',

        }),
        menuList: (provided) => ({
            ...provided,
            maxHeight: '150px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
                width: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#ccc',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                background: '#999'
            }
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px',
            backgroundColor: state.isFocused ? '#efdda8' : '#fff',
            color: '#000',
            '&:hover': {
                backgroundColor: '#efdda8',
                color: '#000'
            }
        })
    };

    return (
        <div>
            <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className='region-state-modal'>
                {loading ? <>Loading ...</> :
                    <div className='container'>
                        <div className='title'>Grade Subject File</div>
                        <form id={'grade-subject'} onSubmit={handleSubmit} className='m-4'>
                            <div className='input-box'>
                                <Select name={"subject_id"}
                                        defaultValue={formData?.subject_id}
                                        value={formData?.subject_id}
                                        onChange={(val)=>handleChange({target:{name:'subject_id',value:val}})}
                                        options={subjectDropdown} styles={customStyles}
                                />
                                {errors.subject_id && <span className="error-message"> {errors.subject_id}</span> }
                            </div>
                            <div className='flex justify-center m-3'>
                                <button className='export-button' type={"submit"}>Add Subject</button>
                            </div>
                        </form>
                        <table className="image-table">
                            <thead>
                            <tr>
                                <th>Sr No</th>
                                <th>Subject Name</th>
                                <th>Delete</th>
                            </tr>
                            </thead>
                            <tbody>
                            {selectedGradeSubject?.map((subject,index) => (
                                <tr key={subject.id}>
                                    <td>{index+1}</td>
                                    <td>{subject.name}</td>
                                    <td> <FontAwesomeIcon className='action-icon' icon={faTrash} onClick={()=> deleteGradeSubject(subject.id)} /></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>}
            </Modal>
        </div>
    );
};

export default AddGradeSubject;
