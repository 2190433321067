// components/SchoolForm.js
import React, {useEffect} from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import TextArea from "../../Layout/Form/TextArea";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import SelectField from "../../Layout/Form/SelectField";
import DateField from "../../Layout/Form/DateField";
import SelectSalesPersonDropdown from "../../Sales/contact-person/SelectSalesPersonDropdown";

const AttendanceForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);


    const validationRules = {
        attendance_status: { required: true},
        reason: { required: true},
    };
    const onSuccess = (res)=>{
        onRequestClose();
        handleExternalFetch?.(res,['daily-report']);

    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged,setFormData } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );

    const getGeoLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setFormData({ ...formData, sod_latitude:latitude, sod_longitude:longitude });

                },
                (error) => {
                    console.error("Error fetching geolocation:", error.message);
                }
            );
        }
    };
    useEffect(() => {
        isOpen &&  !isEditMode &&  getGeoLocation()
    }, [isOpen]);
    console.log(formData,'formData')
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <BreadCrumb first_crumb={isEditMode ? 'Edit Daily Report' : 'Add Attendance'} />
                <form onSubmit={(e) => handleSubmit(e,validationRules,'daily_report',{created_by:auth?.id,user_id:auth?.id,sod_latitude:formData.sod_latitude, sod_longitude:formData.sod_longitude},[])}>
                    <div className='vendor-details'>
                        <SelectSalesPersonDropdown placeholder={"Select Employee"} name="user_id" sales_person_id={formData.user_id} handleChange={handleChange} />

                        <SelectField label={"Attendance Status"}
                                     name={'attendance_status'}
                                     value={formData.attendance_status || ''}
                                     onChange={handleChange}
                                     valueArray={[{ value: '', label: 'Leave status' },
                                         { value: 'Present', label: 'Present' },
                                         { value: 'Absent', label: 'Absent' },
                                         { value: 'Half Day', label: 'Half Day' },
                                         { value: 'Casual Leave', label: 'Casual Leave' },
                                         { value: 'Sick Leave', label: 'Sick Leave' },
                                         { value: 'Planned Leave', label: 'Planned Leave' }]}
                                     error={errors.attendance_status} className='select'/>
                        <DateField label="Report Date"
                                   name={'report_date'}
                                   value={formData.report_date || ''}
                                   onChange={handleChange}
                                   error={errors.report_date} placeholder='Report Date' />
                        <TextArea
                            className='text-area'
                            name="reason"
                            label="Reason"
                            value={formData.reason || ''}
                            onChange={handleChange} placeholder='Enter Reason'
                        />

                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default AttendanceForm;
