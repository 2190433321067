import React from 'react';
import Modal from 'react-modal';
import { useSelector } from "react-redux";

const PurchaseOrderItemModal = ({ isOpen, onRequestClose }) => {
    // Fetch purchase order item data from Redux store
    const purchaseOrderItemData = useSelector(
        (state) => state.inventory?.purchaseOrderDetailData && state.inventory?.purchaseOrderDetailData[0]?.orderItemData
    );

    const handlePrint = () => {
        const printContent = document.getElementById('printable-purchase-table').innerHTML;
        const originalContent = document.body.innerHTML;

        document.body.innerHTML = printContent;
        window.print();
        document.body.innerHTML = originalContent;
        window.location.reload();
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='description-modal' ariaHideApp={false}>
            <div className="container">
                <div className='title'>Purchase Order Items List</div>
            </div>
            <div id="printable-purchase-table" className='m-10'>
                <table className='products-table'>
                    <thead>
                    <tr>
                        <th className='w-20'>Sr. No</th>
                        <th className='w-96'>Item Name</th>
                        <th className='w-96'>Item Photo</th>
                        <th className='w-20'>Quantity</th>
                        <th className='w-28'>Price</th>
                        <th className='w-28'>GST</th>
                        <th className='w-28'>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    {purchaseOrderItemData?.map((item, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.product_name}</td>
                            <td>
                                <img
                                    alt="item"
                                    src={item.product_photo}
                                    height="40px"
                                    className="uploaded-image"
                                />
                            </td>
                            <td>{item.quantity}</td>
                            <td>{item.price}</td>
                            <td>{item.gst}</td>
                            <td>{(item.price * item.quantity + item.gst).toFixed(2)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className='flex justify-around'>
                <button className='submit-button' onClick={handlePrint}>Print</button>
                <button className='submit-button' onClick={onRequestClose}>Close</button>
            </div>
        </Modal>
    );
};

export default PurchaseOrderItemModal;
