// components/SchoolForm.js
import React from 'react';
import Modal from 'react-modal';
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import DOMPurify from 'dompurify';
const TemplatePreview = ({ isOpen, onRequestClose, selectedData }) => {

    const sanitizedContent = DOMPurify.sanitize(selectedData?.html_content);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <BreadCrumb first_crumb='Template Preview'/>
                    <div className='vendor-details-t'>
                        <div><label>Name</label><div>{selectedData.name || ''}</div></div>
                        <div><label>Subject</label><div>{selectedData.subject || ''}</div></div>
                        <div><label>Template Content</label><div style={{ border: "1px solid #ccc", padding: "10px" }}
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}></div></div>
                        <div><label>Text Content</label><div style={{ border: "1px solid #ccc", padding: "10px" }}>{selectedData.text_content || ''}</div></div>
                    </div>
                    <div className='buttons'>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
            </div>
        </Modal>
    );
};

export default TemplatePreview;
