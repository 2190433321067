export const CATEGORY_DATA = "CATEGORY_DATA";
export const SUB_CATEGORY_LIST = "SUB_CATEGORY_LIST";
export const BRAND_DATA = "BRAND_DATA";
export const PRODUCT_DATA = "PRODUCT_DATA";
export const PRODUCT_TABLE_DATA = "PRODUCT_TABLE_DATA";
export const PRODUCT_COMPONENT_DATA = "PRODUCT_COMPONENT_DATA";
export const ORDERS_DETAIL_DATA = "ORDERS_DETAIL_DATA";
export const ORDERS_PROCESSING_DETAIL_DATA = "ORDERS_PROCESSING_DETAIL_DATA";
export const PRODUCT_IMAGE_DATA = "PRODUCT_IMAGE_DATA";
export const PRODUCT_CURRICULUM_DATA = "PRODUCT_CURRICULUM_DATA";
export const PRODUCT_GRADES_DATA = "PRODUCT_GRADES_DATA";
export const PRODUCT_SUBJECTS_DATA = "PRODUCT_SUBJECTS_DATA";
export const PRODUCT_TOPICS_DATA = "PRODUCT_TOPICS_DATA";
export const PRODUCT_CURRICULUM_IMAGES_DATA = "PRODUCT_CURRICULUM_IMAGES_DATA";
export const DISCOUNT_COUPON_LIST_DATA = "DISCOUNT_COUPON_LIST_DATA";
export const PRODUCT_DROPDOWN_LIST_DATA = "PRODUCT_DROPDOWN_LIST_DATA";
export const VENDOR_TRANSPORTER_DROPDOWN_LIST_DATA = "VENDOR_TRANSPORTER_DROPDOWN_LIST_DATA";
export const PACKAGING_ITEMS_DROPDOWN_LIST_DATA = "PACKAGING_ITEMS_DROPDOWN_LIST_DATA";
export const PURCHASE_ITEM_LIST_DATA = "PURCHASE_ITEM_LIST_DATA";