// components/SchoolTable.js
import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useFetchData } from '../../../hooks/useFetchData';
import {actionToGetContactPersonApiCall} from '../../../store/action/salesAction';
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction,actionToGetTagListDropdownApiCall,actionToAddTagToEmailIdApiCall} from '../../../store/action/commonActions';
import SchoolForm from './SchoolForm';
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import {truncateHTMLText} from "../../../store/utility/DatatableUtility";
import useAuth from "../../../hooks/useAuth";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBriefcase,
    faBriefcaseClock,
    faFileCirclePlus,
    faPenToSquare,
    faUser,
    faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import SchoolContactPersonForm from "../contact-person/SchoolContactPersonForm";
import SchoolContactPersonTableModal from "../contact-person/SchoolContactPersonTableModal";
import SchoolClientLeadForm from "../client-lead/SchoolClientLeadForm";
import SchoolClientLeadTableModal from "../client-lead/SchoolClientLeadTableModal";
import {uploadFileOnAws} from "../../../store/action/inventoryActions";
import GemSchoolForm from "./GemSchoolForm";
import { useEffectOnce } from '../../../hooks/useEffectOnce';
import {toast} from "react-toastify";
const SchoolTable = ({setFetchDataTable,fetchDataTable}) => {
    const dispatch = useDispatch();
    const [selectedRows, setSelectedRows] = useState([]);
    const [tagDropdown, setTagDropdown] = useState([]);
    const {auth} = useAuth();
    const ROLES = useSelector((state) => state.webSetting.roleList);
    const tagListDropDownData = useSelector((state) => state.common.tagListDropDownData);
    const allowedRoles =[ROLES.SuperAdmin,ROLES.Admin];
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);
    const hiddenFileInput = useRef(null);
    const [selectedTags, setSelectTags] = useState([]);
    const { data:schoolData, fetchData,settings } = useFetchData(`sales/get-schools`, [], { source: auth?.source });
    const [isSchoolModalOpen, setIsSchoolModalOpen] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);
     // Check if all rows are selected
  const areAllRowsSelected = selectedRows.length === schoolData?.data?.length;
  // Handle global checkbox change
  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedRows(schoolData?.data.map((row) => row.id)); // Select all rows
    } else {
      setSelectedRows([]); // Deselect all rows
    }
  };
 // Handle checkbox change
    const handleCheckboxChange = (event, row) => {
        if (event.target.checked) {
        setSelectedRows((prev) => [...prev, row.id]);
        } else {
        setSelectedRows((prev) => prev.filter((id) => id !== row.id));
        }
    };

    const columns = [
        {
            name: <input
          type="checkbox"
          onChange={handleSelectAllChange}
          checked={areAllRowsSelected}
        />,
            cell: (row) => (
              <input
                type="checkbox"
                onChange={(e) => handleCheckboxChange(e, row)}
                checked={selectedRows.includes(row.id)}
              />
            ),
            width: '50px',
          },
        { name: 'Sr No', selector: row => row.id, selector_field:'id',
            cell: (row,index) => (
                <>
                    {index+1}
                </>
            ),width:'60px'  },
        { name: 'Name', selector: row => row.name, selector_field:'name',
            cell: row => (
                <>
                    {row.name}
                </>
            ), sortable: true ,width:'150px' },
        { name: 'email', selector: row => row.email, selector_field:'email',sortable: true,width:'250px'  },
        { name: 'phone', selector: row => row.phone, selector_field:'phone',sortable: true,width:'120px'  },
        { name: 'creator', selector: row => row.creator, selector_field:'creator',sortable: true,width:'200px' },
        { name: 'Address', selector: row =>  <div className="truncate-text" title={row.address}>
                {truncateHTMLText(row.address, 50)} {/* Adjust maxLength as needed */}
            </div>,
            selector_field:'address',sortable: true ,width:'200px'},
        { name: 'region name', selector: row => row.region_name, selector_field:'region_name',sortable: true,width:'100px' },
        { name: 'state name', selector: row => row.state_name, selector_field:'state_name',sortable: true,width:'100px' },
        { name: 'city name', selector: row => row.city_name, selector_field:'city_name',sortable: true,width:'100px' },
        { name: 'School Type', selector: row => row.school_type_name, selector_field:'school_type_name',sortable: true,width:'100px'},
        { name: 'Image', selector: row => row.photo, selector_field:'sale_price',sortable: false, width:'150px',cell: row => (
                <>
                    <div className={'test'} onClick={()=>{allowedRoles?.includes(auth?.role) && handlePhotoClick(row)}}>
                        <img src={row?.photo} height="35" width="35" alt={row?.name}/></div>
                </>
            ) },
        { name: 'Actions', cell: (row) => (
                <div className='action-components'>
                    <TableEditActions
                        tooltip={'Edit School'}
                        row={row}
                        onEdit={() => { setSelectedSchool(row); setIsSchoolModalOpen('edit'); }}
                    />
                    <TableDeleteActions
                        auth_key="school_delete"
                        tooltip="Delete School"
                        row={row}
                        onDelete={(row) => dispatch(callSoftDeleteDataWithLogFunction({ tableName: 'schools', id: row.id, userId: auth?.id, eventTypeId })).then(fetchData)}
                    />
                    <Tippy content="Add Contact Person">
                        <button className='action-button' onClick={() => { setSelectedSchool(row); setIsSchoolModalOpen('add_contact_person'); }}>
                            <FontAwesomeIcon className='action-icon' icon={faUserPlus} />
                        </button>
                    </Tippy>
                    <Tippy content="Contact Person Table">
                        <button className='action-button' onClick={() => { setSelectedSchool(row); setIsSchoolModalOpen('contact_person_table'); }}>
                            <FontAwesomeIcon className='action-icon' icon={faUser} />
                        </button>
                    </Tippy>
                    <Tippy content="Add Business Regarding">
                        <button className='action-button' onClick={() => { setSelectedSchool(row); setIsSchoolModalOpen('add_client_lead'); }}>
                            <FontAwesomeIcon className='action-icon' icon={faBriefcaseClock} />
                        </button>
                    </Tippy>
                    <Tippy content="Business Regarding Table">
                        <button className='action-button' onClick={() => { setSelectedSchool(row); setIsSchoolModalOpen('client_lead_table');   dispatch(actionToGetContactPersonApiCall({school_id:row.id}));}}>
                            <FontAwesomeIcon className='action-icon' icon={faBriefcase} />
                        </button>
                    </Tippy>
                </div>
            ), width: '150px' }
    ];

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable]);


    useEffect(() => {
        let tagArray = [];
        tagListDropDownData?.map(val=>{
                tagArray.push({value:val.id,label:val.name});
        });
            setTagDropdown(tagArray);
    },[tagListDropDownData]);

    useEffectOnce(()=>{
       dispatch(actionToGetTagListDropdownApiCall()); 
    },[]);
    const handlePhotoClick = (row) => {
        setSelectedSchool(row);
        hiddenFileInput.current.click();    // ADDED
    };
    const handleMainPhotoUpload = async event => {
        const fileUploaded = event.target.files[0];
        let name = selectedSchool?.name.replace(/\s+/g, '-').toLowerCase();
        let filename = `schools/${name}-${selectedSchool.id}.png`;
        let deletedFileName =  selectedSchool.photo?.replace('https://shikshak-solutions.s3.eu-north-1.amazonaws.com/','');
        let url = await dispatch(uploadFileOnAws({filename:filename,deletedFilename:deletedFileName,photo:fileUploaded,type:'image/png'}));
        dispatch(callUpdateDataWithLogFunction({tableName:'schools',data: {photo:url+'?v='+Math.floor(1000 + Math.random() * 9000)},id:selectedSchool?.id,userId:auth?.id,eventTypeId:eventTypeId})).then(() => {
            fetchData()
        })
    }

   // Handle multi-select change
   const handleMultiSelectChange = (selectedOptions) => {
    setSelectTags(selectedOptions || []);
  };
  // Handle Apply Click with Confirmation
  const handleApplyClick = () => {
    const selectedStatusLabels = selectedTags.map((status) => status.label).join(', ');
    const confirmationMessage = `
      Are you sure you want to apply the following tags:
      ${selectedStatusLabels}
      To rows: ${JSON.stringify(selectedRows)}?
    `;
    swal({
      title: "Are you sure?",
      text: confirmationMessage,
      icon: "warning",
      buttons: true,
      dangerMode: true,
  })
      .then(async (success) => {
          if (success) {
              selectedTags?.map(val=>{
                  // API call to add tag to email id
                  dispatch(actionToAddTagToEmailIdApiCall({
                      "columnName":"school_id",
                      "tagId":val?.value,
                      "idArray":selectedRows
                  }))
              });
                toast.success(`Tags successfully applied: ${selectedStatusLabels} to rows: ${JSON.stringify(selectedRows)}!`); // Success notification
                // Removeing the selected rows and selected tags
                setSelectedRows([]);
                setSelectTags([]);
          }
      });
  };

    return (
        <div className='table-container'>
            <DataTableLayout columns={columns} data={schoolData.data} dataTableSetting={settings} fetchData={fetchData} totalRows={schoolData?.total} />


            {isSchoolModalOpen === 'edit' && (
                <SchoolForm
                    handleExternalFetch={fetchData}
                    isOpen={isSchoolModalOpen=== 'edit'}
                    onRequestClose={() => setIsSchoolModalOpen(false)}
                    selectedData={selectedSchool}
                    isEditMode={true}
                />
            )}  {isSchoolModalOpen === 'add_contact_person' && (
                <SchoolContactPersonForm
                    isOpen={isSchoolModalOpen=== 'add_contact_person'}
                    onRequestClose={() => setIsSchoolModalOpen(false)}
                    selectedData={{school_id:selectedSchool?.id,school_name:selectedSchool?.name}}
                    isEditMode={false}
                />
            )} {isSchoolModalOpen === 'contact_person_table' && (
                <SchoolContactPersonTableModal
                    isOpen={isSchoolModalOpen=== 'contact_person_table'}
                    selectedSchool={selectedSchool}
                    onRequestClose={() => setIsSchoolModalOpen(false)}
                />
            )} {isSchoolModalOpen === 'add_client_lead' && (
                <SchoolClientLeadForm
                    isOpen={isSchoolModalOpen=== 'add_client_lead'}
                    onRequestClose={() => setIsSchoolModalOpen(false)}
                    selectedData={{school_id:selectedSchool?.id,school_name:selectedSchool?.name}}
                    isEditMode={false}
                />
            )} {isSchoolModalOpen === 'client_lead_table' && (
                <SchoolClientLeadTableModal
                    isOpen={isSchoolModalOpen=== 'client_lead_table'}
                    selectedSchool={selectedSchool}
                    onRequestClose={() => setIsSchoolModalOpen(false)}
                />
            )}
        {/* Show Change Status multi-select dropdown at the bottom */}
      {selectedRows.length > 0 && (
        <div
          style={{
            marginTop: '20px',
            padding: '10px',
            border: '1px solid #ccc',
            backgroundColor: '#f9f9f9',
            textAlign: 'center',
          }}
        >
          <strong>Apply Tags:</strong>
          <div style={{ marginTop: '10px' }}>
            <Select
              isMulti
              options={tagDropdown}
              value={selectedTags}
              onChange={handleMultiSelectChange}
              placeholder="Select statuses"
              styles={{
                container: (base) => ({ ...base, minWidth: '200px', margin: '0 auto' }),
              }}
            />
            <button
              style={{ margin: '10px', padding: '5px 10px' }}
              onClick={handleApplyClick}
              disabled={selectedTags.length === 0}
            >
              Apply
            </button>
          </div>
          <div>
            <small>Selected rows: {JSON.stringify(selectedRows)}</small>
          </div>
        </div>
      )}
        </div>
    );
};

export default SchoolTable;
