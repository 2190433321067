// components/SchoolForm.js
import React from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";

const TagsForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) => state.marketing.marketingEventTypeId);

    const validationRules = {
        name: { required: true, minLength: 3, maxLength: 255 }
    };
    const onSuccess = (res)=>{
        onRequestClose();
        handleExternalFetch?.(res,['tags']);

    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <BreadCrumb first_crumb={isEditMode ? 'Edit Tag' : 'Add Tag'}/>
                <form onSubmit={(e) => handleSubmit(e,validationRules,'tags',{created_by:auth?.id},[])}>
                    <div className='vendor-details-t'>
                    <InputField
                        label="Tag Name"
                        type="text"
                        name="name"
                        value={formData.name || ''}
                        onChange={handleChange}
                        error={errors.name} placeholder='Enter Name'
                    />

                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default TagsForm;
