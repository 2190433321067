// components/SchoolForm.js
import React,{useState,useEffect} from 'react';
import Modal from 'react-modal';
// import InputField from '../../Layout/Form/InputField';
// import TextArea from '../../Layout/Form/TextArea';
import SelectField from '../../Layout/Form/SelectField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";

const TemplateVariableList = ({ isTemplateListOpen,onRequestVariableListClose,options,handelAddVariable}) => {

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedValue, setSelectedValue] = useState("");
    const handleSearch = (e) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);

        // Filter options based on the search term
        const filtered = options.filter((option) =>
            option.toLowerCase().includes(term)
        );
        setFilteredOptions(filtered);
    };

    const handelVariableSelect = (option) => {
        setSelectedValue(option);
        
    };
    const handelAddVariableButtonAction = () =>{
        handelAddVariable(selectedValue)
    }
    useEffect(() => {
        setSearchTerm("");
        setSelectedValue('');
        setFilteredOptions(options);  
    },[isTemplateListOpen]);
    return (
        <Modal isOpen={isTemplateListOpen} onRequestClose={onRequestVariableListClose} className='Modal'>
            <div className="container">
            <BreadCrumb first_crumb={ 'Variable List'}/>
            <input
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
                style={{
                    width: "100%",
                    padding: "10px",
                    marginBottom: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
            />
            <ul
                style={{
                    listStyleType: "none",
                    padding: 0,
                    margin: 0,
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    maxHeight: "150px",
                    overflowY: "auto",
                }}
            >
        {filteredOptions.map((option, index) => (
                    <li
                        key={index}
                        onClick={() => handelVariableSelect(option)}
                        style={{
                            padding: "10px",
                            cursor: "pointer",
                            background: selectedValue === option ? "#f0f0f0" : "#fff",
                        }}
                    >
                        {option}
                    </li>
                ))}
                {filteredOptions.length === 0 && (
                    <li
                        style={{
                            padding: "10px",
                            color: "#999",
                        }}
                    >
                        No options found
                    </li>
                )}
            </ul>

            
            <div className='buttons'>
            <button className='submit-button' type='button' onClick={handelAddVariableButtonAction} disabled={(selectedValue==='')? true:false} >
                            Add
                        </button>
            <button className='close-button' onClick={onRequestVariableListClose}>Close</button>
                </div>
            </div>
        </Modal>
    );
};

export default TemplateVariableList;
