import React, {useEffect, useState} from "react";
import {makeApiCall} from "../../hooks/api/makeApiCall";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const NotificationBar = () => {
    const [messages, setMessages] = useState([]);

    // Fetch holiday list
    useEffect(() => {
        const fetchNotification = async () => {
            const response = await makeApiCall('/gallery/get-running-notification');
            setMessages(response);
        };
        fetchNotification();
    }, []);

    return (
        <div className="notification-header">
            <div className="overflow-hidden relative">
                <div className="animate-marquee-slow text-red-500 whitespace-nowrap">
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    {messages?.map((msg, index) => (
                        <span key={index} className="mx-4">{msg.message}</span>
                    ))}
                </div>
            </div>
        </div>
    );
};
export default NotificationBar;
