// components/SchoolForm.js
import React, {useEffect} from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useDispatch, useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import TextArea from "../../Layout/Form/TextArea";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import SelectSchoolDropdown from "../../Sales/school/SelectSchoolDropdown";
import SelectContactPersonDropdown from "../../Sales/contact-person/SelectContactPersonDropdown";
import {actionToGetContactPersonApiCall} from "../../../store/action/salesAction";
import {CONTACT_PERSON_DATA} from "../../../store/constant/salesConstant";

const AddSchoolUcDataModal = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch,type }) => {
    const {auth} = useAuth();
    const dispatch = useDispatch();
    const eventTypeId = useSelector((state) => state.inventory.inventoryEventTypeId);

    const validationRules = {
        remarks: { required: false, minLength: 3 },
        school_id: { required: true,field_name:'School' },
        school_contact_person_id: { required: true,field_name:'Contact Person' },
        atl_code: { required: true,field_name:'ATL Code' },
        atl_login_id: { required: true,field_name:'Login ID' },
        uc_status: { required: true,field_name:'UC Status' },
    };
    const onSuccess = (res)=>{
        onRequestClose();
        handleExternalFetch?.(res,['school_documentation']);

    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged,updatePinCode } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );
    useEffect(()=>{
        if(isOpen){
            dispatch({ type: CONTACT_PERSON_DATA, payload: [] });
        }
    },[isOpen])
    useEffect(() => {
        if(formData.school_id){
            dispatch(actionToGetContactPersonApiCall({school_id:formData.school_id}));
        }
    }, [formData.school_id]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className={formData?.school_id ? 'Modal' :'sales-report-modal'}>
            <div className="container">
                <BreadCrumb first_crumb={isEditMode ? 'Edit School UC Details' : 'Add School UC Details'} />
                <form onSubmit={(e) => handleSubmit(e,validationRules,'school_documentation',{created_by:auth?.id},[])}>
                    <div className='vendor-details'>
                        <SelectSchoolDropdown placeholder='Select School' name={'school_id'} school_id={formData?.school_id} handleChange={handleChange} error={errors?.school_id} />
                        {formData?.school_id && <>
                            <SelectContactPersonDropdown placeholder='Select/Add ATL Incharge' school_contact_person_id={formData.school_contact_person_id} name={'school_contact_person_id'} school_id={formData?.school_id} handleChange={handleChange} />
                        <InputField
                            label="Enter ATL Code"
                            type="text"
                            name="atl_code"
                            value={formData.atl_code || ''}
                            onChange={handleChange}
                            error={errors.atl_code} placeholder='Enter ATL Code'
                        />
                        <InputField
                            label="Login ID"
                            type="text"
                            name="atl_login_id"
                            value={formData.atl_login_id || ''}
                            onChange={handleChange}
                            error={errors.atl_login_id } placeholder='Enter School Login ID'
                        />
                        <div className='input-box'>
                            <span className='details'>UC Status</span>
                            <select className='select' name='uc_status' placeholder='Select UC Status' onChange={handleChange} value={formData.uc_status || ''}>
                                <option value="pending">Pending</option>
                                <option value="in review">In Review</option>
                                <option value="approved">Approved</option>
                                <option value="rejected">Rejected</option>
                            </select>
                            {errors.uc_status && <span className="error-message"> {errors.uc_status}</span> }
                        </div>
                        <TextArea
                            className='text-area'
                            name="remarks"
                            label="Remarks"
                            value={formData.remarks || ''}
                            onChange={handleChange} placeholder='Enter Remarks'
                        />
                        </>
                        }
                    </div>
                    <div className='buttons'>
                        {formData?.school_id && <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>}
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default AddSchoolUcDataModal;
