// components/SchoolForm.js
import React, {useEffect} from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import TextArea from "../../Layout/Form/TextArea";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";

const DailyReportForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);


    const validationRules = {
        sod_time: { required: true, minLength: 3, maxLength: 50 },
        eod_time: { required: true, minLength: 3, maxLength: 50 },
        today_work: { required: true},
    };
    const onSuccess = (res)=>{
        onRequestClose();
        handleExternalFetch?.(res,['daily-report']);

    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged,setFormData } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );

    const getGeoLocation = () => {
        console.log(navigator.geolocation,'geo')
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log(position,'pos')
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setFormData({ ...formData, sod_latitude:latitude, sod_longitude:longitude });
                    console.log("Latitude:", latitude, "Longitude:", longitude);
                    // Here you can send the location data to your server
                },
                (error) => {
                    console.error("Error fetching geolocation:", error.message);
                }
            );
        }
    };
    useEffect(() => {
        isOpen && !isEditMode && getGeoLocation()
    }, [isOpen]);
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container">
                <BreadCrumb first_crumb={isEditMode ? 'Edit Daily Report' : 'Add Daily Report'} />
                <form onSubmit={(e) => handleSubmit(e,validationRules,'daily_report',{created_by:auth?.id,user_id:auth?.id,sod_latitude:formData.sod_latitude, sod_longitude:formData.sod_longitude},[])}>
                    <div className='vendor-details'>
                    <InputField
                        label="SOD Time"
                        type="text"
                        name="sod_time"
                        value={formData.sod_time || ''}
                        onChange={handleChange}
                        error={errors.sod_time} placeholder='Enter SOD Time'
                    />
                    <InputField
                        label="EOD Time"
                        type="text"
                        name="eod_time"
                        value={formData.eod_time || ''}
                        onChange={handleChange}
                        error={errors.eod_time} placeholder='Enter EOD Time'
                    />

                    <TextArea
                        className='text-area'
                        name="today_work"
                        label="Today Work"
                        value={formData.today_work || ''}
                        onChange={handleChange} placeholder='Enter Today Work'
                    />

                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default DailyReportForm;
