// Reusable Input Component
import React from "react";
const TextArea = ({ label, className, name, onChange, value, error, placeholder,disabled }) => (
    <div className='input-box'>
        <span className='details'>{label}</span>
        <textarea
            className={className}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
        />
        {error && <span className="error-message">{error}</span>}
    </div>
);
export default TextArea;
