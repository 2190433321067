import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import {
    actionToDeleteAwsObject,
    actionToDeleteStemcityAwsObject,
    callDeleteDataWithLogFunction,
    callInsertDataWithLogFunction,
} from '../../../store/action/commonActions';
import { uploadFileOnAws } from '../../../store/action/inventoryActions';
import useAuth from '../../../hooks/useAuth';
import { actionToGetProductCurriculumImagesApiCall } from '../../../store/action/inventoryActions';
import { actionToGetCurriculumFileByCurriculumIdApiCall } from '../../../store/action/curriculumAction';
import AddCurriculumContent from './AddCurriculumContent';
import EditCurriculumContent from './EditCurriculumContent';

const CurriculumFile = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [editingItem, setEditingItem] = useState(null);
    const curriculumImageData = useSelector((state) => state.curriculum?.curriculumContentListData);
    const { auth } = useAuth();
    const eventTypeId = useSelector((state) => state.curriculum.curriculumEventTypeId);
    const selectedCurriculum = props.selectedCurriculum;

    const deleteProductCurriculumPhoto = (id, imgUrl) => {
        swal({
            title: 'Are you sure?',
            text: 'You want to delete this product curriculum photo',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then(async (success) => {
            if (success) {
                dispatch(callDeleteDataWithLogFunction({ tableName: 'curriculum_content', id: id, userId: auth?.id, eventTypeId })).then(() => {
                    let imageUrl = imgUrl.replace('https://shikshak-solutions.s3.eu-north-1.amazonaws.com/', '');
                    dispatch(actionToDeleteAwsObject({ key: imageUrl }));
                    dispatch(actionToGetProductCurriculumImagesApiCall({ product_curriculum_id: selectedCurriculum.id }));
                });
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        if (selectedCurriculum?.id && props.isOpen) {
            dispatch(actionToGetCurriculumFileByCurriculumIdApiCall({ curriculum_id: selectedCurriculum.id })).then(() => {
                setLoading(false);
            });
        }
    }, [selectedCurriculum]);

    const handleEdit = (item) => {
        setEditingItem(item);
    };

    const handleSave = (updatedItem) => {
        // Dispatch action to save the updated item
        // After saving, reset editingItem to null
        setEditingItem(null);
    };

    const handleCancel = () => {
        setEditingItem(null);
    };

    return (
        <div>
            <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose} className="Modal">
                {loading ? (
                    <>Loading ...</>
                ) : (
                    <div className="container max-h-[80vh] overflow-y-auto rounded-lg w-[90%]">
                        <div className="title">Curriculum File</div>
                        {editingItem ? (
                            <div className="edit-form-container">
                                <EditCurriculumContent
                                    selectedCurriculum={selectedCurriculum}
                                    initialData={editingItem}
                                    onSave={handleSave}
                                    onCancel={handleCancel}
                                />
                            </div>
                        ) : (
                            <AddCurriculumContent selectedCurriculum={selectedCurriculum} callFrom={'curriculum-file'} />
                        )}
                        <table className="image-table">
                            <thead>
                            <tr>
                                <th>Sr No</th>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Poster</th>
                                <th>Ordering</th>
                                <th>Type</th>
                                <th>Flipbook Code</th>
                                <th>Video Duration</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {curriculumImageData !== 'loading' &&
                                curriculumImageData?.map((image, index) => (
                                    <tr key={image.id}>
                                        <td>{index + 1}</td>
                                        <td>{image.name}</td>
                                        <td>
                                            <img
                                                src={image.url}
                                                alt={`Uploaded ${selectedCurriculum?.name} ${index + 1}`}
                                                className="uploaded-image"
                                            />
                                        </td>
                                        <td>
                                            <img
                                                src={image.poster_url}
                                                alt={`Uploaded ${selectedCurriculum?.name} ${index + 1}`}
                                                className="uploaded-image"
                                            />
                                        </td>
                                        <td>{image.ordering}</td>
                                        <td>{image.type}</td>
                                        <td>{image.flipbook_code}</td>
                                        <td>{image.video_duration_in_seconds}</td>
                                        <td>
                                            <p className="description-text">{image.description}</p>
                                        </td>
                                        <td className="w-20">
                                            <div className="flex justify-between">
                                                <FontAwesomeIcon
                                                    className="action-icon"
                                                    icon={faPenToSquare}
                                                    onClick={() => handleEdit(image)}
                                                />
                                                <FontAwesomeIcon
                                                    className="action-icon"
                                                    icon={faTrash}
                                                    onClick={() => deleteProductCurriculumPhoto(image.id, image.url)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default CurriculumFile;
