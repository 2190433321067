import React, { useState, useEffect } from 'react';
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import Modal from "react-modal";
import SelectProductDropdown from "../product/SelectProductDropdown";
import { formatCurrency } from "../../../store/utility/DatatableUtility";
import useAuth from "../../../hooks/useAuth";
import { makeApiCall } from "../../../hooks/api/makeApiCall";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import SelectVendorTransporterDropdown from "../transporter/SelectVendorTransporterDropdown";
import SelectCompanyDropdown from "../../auth/SelectCompanyDropdown";

function CreatePurchaseOrderForm({ isOpen, onRequestClose, isEditMode, initialProducts, handleExternalFetch }) {
    const [products, setProducts] = useState([{ product_id: '', quantity: 1, price: 0, gstRate: 18, gst: 0, subtotal: 0 }]);
    const [vendor, setVendor] = useState(null); // Vendor dropdown state
    const [purchaseOrderNo, setPurchaseOrderNo] = useState(''); // Purchase order number
    const [orderReference, setOrderReference] = useState(''); // Order reference
    const [porter, setPorter] = useState(''); // Porter information
    const [company, setCompany] = useState(1); // Company information
    const [porterPayment, setPorterPayment] = useState(0); // Porter payment
    const [remark, setRemark] = useState(''); // Remark field
    const [grandTotal, setGrandTotal] = useState(0);
    const [totalGST, setTotalGST] = useState(0);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const { auth } = useAuth();

    useEffect(() => {
        if (isEditMode && initialProducts) {
            setProducts(initialProducts.map(product => ({
                ...product,
                gst: product.price * (product.gstRate / 100),
                subtotal: product.price * product.quantity,
            })));
            calculateGrandTotal(initialProducts);
        }
    }, [isEditMode, initialProducts]);

    const handleProductChange = (index, event) => {
        const { name, value } = event.target;
        const updatedProducts = [...products];
        updatedProducts[index][name] = name === 'quantity' || name === 'price' || name === 'gstRate' ? parseFloat(value) : value;

        if (name === 'product_id') {
            const duplicate = updatedProducts.some((product, i) => i !== index && product.product_id === value);
            if (duplicate) {
                setError('This product is already added. Please select a different product.');
                updatedProducts[index][name] = '';
            } else {
                setError(null);
                updatedProducts[index].price = parseFloat(event.target.price || 0);
            }
        }

        updatedProducts[index].gst = updatedProducts[index].price * updatedProducts[index].quantity * (updatedProducts[index].gstRate / 100);
        setProducts(updatedProducts);
        calculateGrandTotal(updatedProducts);
    };

    const calculateGrandTotal = (products) => {
        const total = products.reduce((sum, product) => sum + (product.price + (product.price * product.gstRate / 100)) * product.quantity , 0);
        const gst = products.reduce((sum, product) => sum + product.gst, 0);

        setGrandTotal(total);
        setTotalGST(gst);
    };

    const addProduct = () => {
        setProducts([...products, { product_id: '', quantity: 1, price: 0, gstRate: 18, gst: 0, subtotal: 0 }]);
    };

    const removeProduct = (index) => {
        const updatedProducts = products.filter((_, i) => i !== index);
        setProducts(updatedProducts);
        calculateGrandTotal(updatedProducts);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const validProducts = products.filter(product => product.product_id).map(product => ({
            product_id: product.product_id,
            quantity: product.quantity,
            price: product.price*product.quantity,
            gst: product.gst
        }));

        if (validProducts.length > 0) {
            let purchaseOrder = {
                total: grandTotal,
                vendor_id: vendor, // Supplier information
                status: 'Pending',
                gst_amount: totalGST,
                total_after_discount: grandTotal,
                company_id: company,
                order_reference: orderReference, // Optional order reference
                purchase_order_no: purchaseOrderNo, // Purchase Order No
                porter: porter, // Porter information
                porter_payment: porterPayment ?? 0, // Porter payment
                remark: remark // Remark
            };
            let payload = {
                purchaseOrder: purchaseOrder,
                userId: auth?.id,
                order_items: validProducts
            };
            makeApiCall('/inventory/create-purchase-order', null, dispatch, payload).then(response => {
                handleExternalFetch?.(response,['purchase']);
                onRequestClose();
            });
        } else {
            setError('Please add at least one product to create a purchase order.');
        }
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='create-order-modal'>
            <form action='#' onSubmit={handleSubmit}>
                <div className="description-container max-h-[80vh] overflow-y-auto p-5 bg-white rounded-lg w-[90%]">
                    <BreadCrumb first_crumb={isEditMode ? 'Edit Purchase Order' : 'Create Purchase Order'} />

                    {error && <div className="error-message">{error}</div>}

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <SelectVendorTransporterDropdown
                                placeholder="Select Vendor"
                                name="supplier_id"
                                type={'vendor'}
                                vendor_transporter_id={vendor}
                                handleChange={(selectedVendor) => setVendor(selectedVendor?.target?.value)}
                            />
                        </div>
                        <SelectCompanyDropdown name={"company_id"} allowedRoles={[1,2,9]} source={company}  handleChange={(selectedCompany) =>setCompany(selectedCompany?.target?.value)} />
                        <div>
                            <label htmlFor="purchaseOrderNo" className="block text-sm font-medium text-gray-700">Purchase Order No</label>
                            <input
                                type="text"
                                id="purchaseOrderNo"
                                value={purchaseOrderNo}
                                onChange={(e) => setPurchaseOrderNo(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div>
                            <label htmlFor="orderReference" className="block text-sm font-medium text-gray-700">Order Reference</label>
                            <input
                                type="text"
                                id="orderReference"
                                value={orderReference}
                                onChange={(e) => setOrderReference(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div>
                            <label htmlFor="porter" className="block text-sm font-medium text-gray-700">Porter</label>
                            <input
                                type="text"
                                id="porter"
                                value={porter}
                                onChange={(e) => setPorter(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div>
                            <label htmlFor="porterPayment" className="block text-sm font-medium text-gray-700">Porter Payment</label>
                            <input
                                type="number"
                                id="porterPayment"
                                value={porterPayment}
                                onChange={(e) => setPorterPayment(parseFloat(e.target.value))}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>

                        <div>
                            <label htmlFor="remark" className="block text-sm font-medium text-gray-700">Remark</label>
                            <textarea
                                id="remark"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    <table className='products-table'>
                        <thead>
                        <tr>
                            <th className='w-88'>Product Name</th>
                            <th className='w-28'>Quantity</th>
                            <th className='w-28'>Price</th>
                            <th className='w-28'>GST Rate (%)</th>
                            <th className='w-28'>GST Amount</th>
                            <th className='w-28'>Total</th>
                            <th className='w-12'>Action</th>
                        </tr>
                        </thead>
                        {products.map((product, index) => (
                            <tbody key={index}>
                            <tr>
                                <td>
                                    <SelectProductDropdown
                                        name="product_id"
                                        placeholder="Product Name"
                                        product_id={product.product_id}
                                        index={index}
                                        handleChange={handleProductChange}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        name="quantity"
                                        value={product.quantity}
                                        min="1"
                                        onChange={(event) => handleProductChange(index, event)}
                                        required
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        name="price"
                                        value={product.price}
                                        min="0"
                                        step="0.01"
                                        onChange={(event) => handleProductChange(index, event)}
                                        required
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        name="gstRate"
                                        value={product.gstRate}
                                        min="0"
                                        step="0.01"
                                        onChange={(event) => handleProductChange(index, event)}
                                        required
                                    />
                                </td>
                                <td>
                                    <div className='input-box'>
                                        {formatCurrency(product.gst)}
                                    </div>
                                </td>
                                <td>
                                    <div className='input-box'>
                                        {formatCurrency(product.price * product.quantity)}
                                    </div>
                                </td>
                                <td>
                                    <button className='action-button' onClick={() => removeProduct(index)}>
                                        <FontAwesomeIcon className='action-icon' icon={faTrash} />
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>

                    <button className='product-submit-button rounded-xl p-2 m-2' onClick={addProduct} type="button">
                        <FontAwesomeIcon className='action-icon' icon={faPlus} /> Products
                    </button>
                    <div className='flex flex-col text-right'>
                        <h3 className='text-xl font-semibold'>Subtotal: {formatCurrency(grandTotal - totalGST)}</h3>
                        <h3 className='text-xl font-semibold'>Total GST: {formatCurrency(totalGST)}</h3>
                        <h3 className='text-xl font-semibold'>Grand Total: {formatCurrency(grandTotal)}</h3>
                    </div>
                </div>
                <div className='buttons'>
                    <div className='button'>
                        <button type="submit" className='submit-button'>
                            {isEditMode ? 'Update' : 'Create'}
                        </button>
                    </div>
                    <div className='button'>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </div>
            </form>
        </Modal>
    );
}

export default CreatePurchaseOrderForm;
