import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import DailyReportComponent from "../Components/daily-report/DailyReportComponent";
export default function DailyReportPage() {
    return (
        <>
            <Routes>
                <Route path="" exact element={<Navigate to="daily-report" replace />} />
                <Route path="/:tabName" element={<DailyReportComponent />} />
            </Routes>
        </>
    )
}