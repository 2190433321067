import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchData } from '../../../hooks/useFetchData';
import {callDeleteDataWithLogFunction} from '../../../store/action/commonActions';
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import useAuth from "../../../hooks/useAuth";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import SchoolClientLeadInteractionForm from "../client-lead-interaction/SchoolClientLeadInteractionForm";
import {indianDateFormat} from "../../../store/utility/DatatableUtility";
import SelectBusinessTypeDropdown from "../contact-person/SelectBusinessTypeDropdown";
import SelectSalesPersonDropdown from "../contact-person/SelectSalesPersonDropdown";
import SelectStateDropdown from "../region/SelectStateDropdown";
import DateField from "../../Layout/Form/DateField";
import SelectSchoolTypeDropdown from "../school/SelectSchoolTypeDropdown";

const SalesReportTable = ({setFetchDataTable,fetchDataTable,selectedSchool}) => {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);
    const [advanceFilter, setAdvanceFilter] = useState({ school_id: selectedSchool?.id });

    const { data:schoolData, fetchData,settings } = useFetchData(`sales/get-school-client-lead-interaction`, [], advanceFilter);
    const [isEditSchoolOpen, setIsEditSchoolOpen] = useState(false);
    const [selectedSchoolClientLead, setSelectedSchoolClientLead] = useState(null);
    const ROLES = useSelector((state) =>  state.webSetting.roleList);

    const columns = [
        {
            name: 'Sr No',
            selector: row => row.id,
            selector_field: 'id',
            cell: (row, index) => (
                <>
                    {index + 1}
                </>
            ),
            width: '60px',
            style: {
                position: 'sticky',
                left: 0,
                top: 0, // Ensures it sticks during vertical scrolling as well
                zIndex: 3, // Ensure it stays above content rows and headers
                backgroundColor: '#fff',
                borderRight: '1px solid #ddd', // Optional: adds a dividing line for better visibility
            },
        },
        {
            name: 'School',
            selector: row => row.school_name,
            selector_field: 'school_name',
            sortable: true,
            width: '100px',
            style: {
                position: 'sticky',
                left: '60px', // Positioned to the right of "Sr No"
                top: 0, // Ensures it sticks during vertical scrolling as well
                zIndex: 3, // Matches the z-index of the "Sr No" column
                backgroundColor: '#fff',
                borderRight: '1px solid #ddd', // Optional for better UI
            },
        },
        { name: 'State', selector: row => row.state_name, selector_field:'state_name',sortable: true,width:'100px' },
        { name: 'Contact Person', selector: row => row.school_contact_person_name, selector_field:'school_contact_person_name',sortable: true,width:'200px' },
        { name: 'Mobile Number', selector: row => row.school_contact_person_phone, selector_field:'school_contact_person_phone',sortable: true,width:'200px' },
        { name: 'Lead Name', selector: row => row.lead_name, selector_field:'lead_name',sortable: true,width:'200px' },
        { name: 'Note', selector: row => row.notes, selector_field:'notes', sortable: true ,width:'150px' },
        { name: 'Current Follow Up Type', selector: row => row.current_follow_up_type_name, selector_field:'current_follow_up_type_name', sortable: true ,width:'150px' },
        { name: 'Interaction Date', selector: row => row.interaction_date && indianDateFormat(row.interaction_date), selector_field:'interaction_date', sortable: true ,width:'150px' },
        { name: 'Next Follow Up Date', selector: row => row.next_follow_up_date && indianDateFormat(row.next_follow_up_date), selector_field:'next_follow_up_date', sortable: true ,width:'150px' },
        { name: 'Next Follow Up Type', selector: row => row.next_follow_up_type_name, selector_field:'next_follow_up_type_name', sortable: true ,width:'150px' },
        { name: 'Order Type', selector: row => row.order_type_name, selector_field:'order_type_name',sortable: true,width:'100px'  },
        { name: 'order_value', selector: row => row.order_value, selector_field:'order_value',sortable: true,width:'100px'  },
        { name: 'Sales Person', selector: row => row.creator, selector_field:'creator',sortable: true,width:'200px' },
         { name: 'Lead Status', selector: row => row.lead_status, selector_field:'lead_status',sortable: true,width:'100px' },
        { name: 'Actions', cell: (row) => (
                <div className='action-components'>
                    <TableEditActions
                        row={row}
                        onEdit={() => { setSelectedSchoolClientLead(row); setIsEditSchoolOpen(true); }}
                    />
                    <TableDeleteActions
                        tooltip={"delete"}
                        row={row}
                        onDelete={(row) => dispatch(callDeleteDataWithLogFunction({ tableName: 'school_client_lead_interaction', id: row.id, userId: auth?.id, eventTypeId })).then(fetchData)}
                    />
                </div>
            ), width: '150px' }
    ];

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...advanceFilter,
            [name]: value
        };
        setAdvanceFilter(updatedFormData);
        setFetchDataTable(true);
    };
    return (
        <>
                <div className='ml-5 mt-3'>
                        {/*<h1 className='text-xl'>Apply Filters:</h1>*/}
                    <div className='vendor-details xl:flex lg:flex md:grid sm:grid justify-around'>
                        <div className='xl:flex xl:flex-row lg:flex lg:flex-row md:flex-col sm:flex-col'>
                            {auth?.role !== ROLES.SalesTeam && <><SelectBusinessTypeDropdown name="s.business_type_id" business_type_id={advanceFilter['s.business_type_id']} handleChange={handleChange} />
                            <SelectSalesPersonDropdown name="scli.created_by" sales_person_id={advanceFilter['scli.created_by']} handleChange={handleChange} /></>}
                            <SelectStateDropdown name="addr.state_id" state_id={advanceFilter['addr.state_id']} handleChange={handleChange} />
                            <SelectSchoolTypeDropdown name="school.school_type_id" school_type_id={advanceFilter['school.school_type_id']} handleChange={handleChange} />
                        </div>
                        <div className='xl:flex xl:flex-row lg:flex lg:flex-row md:flex-col sm:flex-col'>
                            <DateField label="From Interaction Date"
                                   name={'scli.from_interaction_date'}
                                   value={advanceFilter['scli.from_interaction_date'] || ''}
                                   onChange={handleChange}
                                   placeholder='From Interaction Date'/>
                            <DateField label="To Interaction Date"
                                   name={'scli.to_interaction_date'}
                                   value={advanceFilter['scli.to_interaction_date'] || ''}
                                   onChange={handleChange}
                                   placeholder='To Interaction Date' />
                            <DateField label="From Follow Up Date"
                                   name={'scli.from_next_follow_up_date'}
                                   value={advanceFilter['scli.from_next_follow_up_date'] || ''}
                                   onChange={handleChange}
                                   placeholder='From Follow Up Date' />
                            <DateField label="To Follow Up Date"
                                   name={'scli.to_next_follow_up_date'}
                                   value={advanceFilter['scli.to_next_follow_up_date'] || ''}
                                   onChange={handleChange}
                                   placeholder='To Follow Up Date' />
                        </div>
                    </div>
                </div>
            <DataTableLayout columns={columns} data={schoolData.data} dataTableSetting={settings} fetchData={fetchData} totalRows={schoolData?.total}  />
           {isEditSchoolOpen && (
                <SchoolClientLeadInteractionForm
                    handleExternalFetch={fetchData}
                    isOpen={isEditSchoolOpen}
                    onRequestClose={() => setIsEditSchoolOpen(false)}
                    selectedData={selectedSchoolClientLead}
                    isEditMode={true}
                />
            )}
        </>
    );
};

export default SalesReportTable;
