import React, { useEffect, useState } from "react";
import {makeApiCall} from "../../hooks/api/makeApiCall";

const DashboardMyLeaveCount = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                setLoading(true);
                const response = await makeApiCall("/gallery/get-dashboard-my-leave-count");
                setData(response[0]);
            } catch (err) {
                setError("Failed to load data.");
            } finally {
                setLoading(false);
            }
        };

        fetchDashboardData();
    }, []);

    if (loading) {
        return <div className="text-center text-gray-600">Loading...</div>;
    }

    if (error) {
        return <div className="text-center text-red-600">{error}</div>;
    }

    return (
        <>
            {data &&
        <div className="p-2">
            <h1 className="text-lg md:text-xl font-bold mb-4">My Leave Count</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div className="grid lg:grid-cols-2 xl:grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-4">
                    <div className="p-4 bg-green-100 text-green-800 rounded-lg shadow flex flex-col justify-between h-32">
                        <h2 className="text-sm md:text-lg font-semibold">Total Present</h2>
                        <p className="text-2xl md:text-3xl">{data.total_present}</p>
                    </div>
                    <div className="p-4 bg-red-100 text-red-800 rounded-lg shadow flex flex-col justify-between h-32">
                        <h2 className="text-sm md:text-lg font-semibold">Total Absent</h2>
                        <p className="text-2xl md:text-3xl">{data.total_absent}</p>
                    </div>
                    <div className="p-4 bg-blue-100 text-blue-800 rounded-lg shadow flex flex-col justify-between h-32">
                        <h2 className="text-sm md:text-lg font-semibold">Total Leaves</h2>
                        <p className="text-2xl md:text-3xl">{data.total_leaves}</p>
                    </div>
                    <div className="p-4 bg-yellow-100 text-yellow-800 rounded-lg shadow flex flex-col justify-between h-32">
                        <h2 className="text-sm md:text-lg font-semibold">Total Holidays</h2>
                        <p className="text-2xl md:text-3xl">{data.total_holidays}</p>
                    </div>
                </div>

                {/* Table container */}
                <div className="p-4 bg-yellow-100 text-yellow-800 rounded-lg shadow">
                    <h2 className="text-sm md:text-lg font-semibold">Details</h2>
                    <div className="p-4 overflow-x-auto">
                        <div className="max-h-48 overflow-y-auto custom-scrollbar">
                            <table className="table-auto w-full border-collapse border border-gray-300 text-sm sm:text-base">
                                <thead>
                                <tr className="bg-gray-100">
                                    <th className="border border-gray-300 px-4 py-2 text-left">Date</th>
                                    <th className="border border-gray-300 px-4 py-2 text-left">Day</th>
                                    <th className="border border-gray-300 px-4 py-2 text-left">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {data.day_date_array
                                    ?.filter((item) => item.status !== 'Present')
                                    .map((item, index) => (
                                        <tr key={index} className="hover:bg-gray-50">
                                            <td className="border border-gray-300 px-4 py-2">{item.date}</td>
                                            <td className="border border-gray-300 px-4 py-2">{item.day}</td>
                                            <td className="border border-gray-300 px-4 py-2">{item.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
</>

    );
};

export default DashboardMyLeaveCount;
