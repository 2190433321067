import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CreatableSelectField from "../../Layout/Form/CreatableSelectField";
import {makeApiCall} from "../../../hooks/api/makeApiCall";
import TransporterForm from "./TransporterForm";
import {VENDOR_TRANSPORTER_DROPDOWN_LIST_DATA} from "../../../store/constant/inventoryConstant";

const SelectVendorTransporterDropdown = ({vendor_transporter_id,name,handleChange,placeholder,error,type}) =>{
    const dispatch=useDispatch();
    const vendorTransporterDropdownListData = useSelector((state) =>  state.inventory?.vendorTransporterDropdownListData);
    const [isEditTransporterOpen, setIsEditTransporterOpen] = useState(false);
    const [selectedTransporter, setSelectedTransporter] = useState(null);
    const [dataDropdown, setDataDropdown] = useState([]);
    const [selectedDropDown, setSelectedDropDown] = useState(null);

    useEffect(() => {
        let componentArray = [];
        let selectedData = null;
        vendorTransporterDropdownListData !== 'loading'  && vendorTransporterDropdownListData.length > 0 && vendorTransporterDropdownListData?.map(val=>{
                if(type === val.type) componentArray.push({value:val.id,label:val.name});
                if(vendor_transporter_id == val.id) selectedData={value:val.id,label:val.name};
        })
        setSelectedDropDown(selectedData);
        setDataDropdown([...componentArray]);
    }, [vendorTransporterDropdownListData,vendor_transporter_id]);
    const handleCreate = (val) => {
        setSelectedTransporter({name:val});
        setIsEditTransporterOpen('add_transporter');
    };
    const handleFetchData = (res)=>{
        makeApiCall(`inventory/get-vendor-transporter-dropdown`,VENDOR_TRANSPORTER_DROPDOWN_LIST_DATA,dispatch).then(response=>{
            handleChange({target:{name:name,value:res.id}});
            }
        );
    }
    const customStyles = {controlHeight:'50px', menuListMaxHeight:'150px'}


    return(<>
        <CreatableSelectField name={name} label={placeholder} customStyles={customStyles} placeholder={placeholder} error={error} dataDropdown={dataDropdown}
                                  handleCreate={handleCreate} selectedDropDown={selectedDropDown} onChange={handleChange}/>
        {isEditTransporterOpen === 'add_transporter' && (
            <TransporterForm
                handleExternalFetch={handleFetchData}
                isOpen={isEditTransporterOpen==='add_transporter'}
                onRequestClose={() => setIsEditTransporterOpen(false)}
                selectedData={selectedTransporter}
                isEditMode={false}
                type={type}
            />
        )}
    </>
    )
}
export default SelectVendorTransporterDropdown;
