import React, { useState, useEffect, useRef } from 'react';
import logo from "../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBoxOpen,
    faUserLarge,
    faRightFromBracket,
    faHouse,
    faPeopleGroup,
    faGears,
    faGraduationCap,
    faFile,
    faImage,
    faEnvelopesBulk,
    faBars
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';
import Tippy from "@tippyjs/react";
import { actionToLogout } from "../../store/action/usersActions";
import { useDispatch } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { subDomainUrl } from "../../hooks/config";
import NotificationBar from "./NotificationBar";

function Header() {
    const { auth, setAuth } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef(null);

    const menuArray = [
        { url: subDomainUrl + '/home', toolTip: 'Home', icon: faHouse, authorized_role: [1, 2, 3, 4, 5, 6, 7, 9], name: 'Home' },
        { url: subDomainUrl + '/inventory', toolTip: 'Inventory', icon: faBoxOpen, authorized_role: [1, 2, 4, 5, 9], name: 'Inventory' },
        { url: subDomainUrl + '/users', toolTip: 'User', icon: faUserLarge, authorized_role: [1, 2, 9], name: 'Users' },
        { url: subDomainUrl + '/sales', toolTip: 'Sales Management', icon: faPeopleGroup, authorized_role: [1, 2, 3, 9], name: 'Sales' },
        { url: subDomainUrl + '/marketing', toolTip: 'Marketing Management', icon: faEnvelopesBulk, authorized_role: [1, 2, 3, 9], name: 'Marketing' },
        { url: subDomainUrl + '/website-setting', toolTip: 'Website Setting', icon: faGears, authorized_role: [1, 2, 4, 9], name: 'Settings' },
        { url: subDomainUrl + '/curriculum', toolTip: 'Curriculum', icon: faGraduationCap, authorized_role: [1, 2, 5, 6, 9], name: 'Curriculum' },
        { url: subDomainUrl + '/daily-report', toolTip: 'Daily Report', icon: faFile, authorized_role: [1, 2, 3, 4, 5, 6, 7, 9], name: 'Daily Report' },
    ];

    const logout = () => {
        dispatch(actionToLogout());
        setAuth({});
        navigate(subDomainUrl + '/login');
    };

    useEffect(() => {
        // Close the menu if clicked outside
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <div className={`header ${menuOpen ? 'menu-open' : ''}`}>
                <div className='header-logo'>
                    <img className='logo' alt={"shikshak solutions"} src={logo} />
                </div>
                {/* Mobile Menu Icon */}
                <div className="menu-icon" onClick={() => setMenuOpen(!menuOpen)}>
                    <FontAwesomeIcon icon={faBars} />
                </div>

                {/* Menu Items */}
                <div className={`header-menu ${menuOpen ? 'open' : ''}`} ref={menuRef}>
                    {menuArray.map((menu, index) => {
                        if (menu.authorized_role?.includes(auth?.role)) {
                            return (
                                <Link to={menu.url} key={index} className='no-underline'>
                                    <div className='subheader-components'>
                                        <Tippy content={menu.toolTip}>
                                            <button className='header-icons'>
                                                <FontAwesomeIcon icon={menu.icon} />
                                                <span className='menu-name'>{menu.name}</span>
                                            </button>
                                        </Tippy>
                                    </div>
                                </Link>
                            );
                        }
                    })}

                    <div className='logout-icon' onClick={logout}>
                        <Tippy content='Log Out'>
                            <button className='header-icons'>
                                <FontAwesomeIcon icon={faRightFromBracket} />
                            </button>
                        </Tippy>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;
