// components/SchoolContactPersonTable.js
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchData } from '../../../hooks/useFetchData';
import { callSoftDeleteDataWithLogFunction } from '../../../store/action/commonActions';
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import useAuth from "../../../hooks/useAuth";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import SchoolContactPersonForm from "./SchoolContactPersonForm";
import Tippy from "@tippyjs/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBox} from "@fortawesome/free-solid-svg-icons";
import SchoolClientLeadForm from "../client-lead/SchoolClientLeadForm";

const SchoolContactPersonTable = ({setFetchDataTable,fetchDataTable,selectedSchool}) => {
    const dispatch = useDispatch();
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const { data:schoolData, fetchData,settings } = useFetchData(`sales/get-school-contact-person`, [], { school_id: selectedSchool?.id });
    const [isEditSchoolContactPersonOpen, setIsEditSchoolContactPersonOpen] = useState(false);
    const [selectedSchoolContactPerson, setSelectedSchoolContactPerson] = useState(null);
    const [columns, setColumns] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [filteredData, setFilterededRows] = useState([]);

   // Handle the "select all" checkbox
  const handleSelectAllChange = (event) => {
    console.log(...filteredData,"filteredData");
    console.log(...schoolData,"schoolData");
    if (event.target.checked) {
      setSelectedRows(schoolData?.data?.map((row) => row.id)); // Select all rows
    } else {
      setSelectedRows([]); // Deselect all rows
    }
  };


    // Handle checkbox change
    const handleCheckboxChange = (event, row) => {
      if (event.target.checked) {
      setSelectedRows((prev) => [...prev, row.id]);
      } else {
      setSelectedRows((prev) => prev.filter((id) => id !== row.id));
      }
  };
  useEffect(()=>{
    console.log(schoolData,"schoolData")
  setFilterededRows(schoolData);
  },[schoolData])
  // Check if all rows are selected
  const areAllRowsSelected = selectedRows?.length === schoolData?.data?.length;
  console.log(selectedRows,"selectedRows");


  
    // Define default columns
    const defaultColumns = [
      {
        name: <input
        type="checkbox"
        onChange={handleSelectAllChange}
        checked={areAllRowsSelected}
      />,
        cell: (row) => (
          <input
            type="checkbox"
            onChange={(e) => handleCheckboxChange(e, row)}
          />
        ),
        width: '50px',
      },
        { name: 'Sr No', selector: row => row.id, selector_field:'id',
            cell: (row,index) => (
                <>
                    {index+1}
                </>
            ),width:'60px'  },
        { name: 'Name', selector: row => row.name, selector_field:'name',
            cell: row => (
                <>
                    {row.name}
                </>
            ), sortable: true ,width:'150px' },
        { name: 'email', selector: row => row.email, selector_field:'email',sortable: true,width:'250px'  },
        { name: 'phone', selector: row => row.phone, selector_field:'phone',sortable: true,width:'200px'  },
       /* { name: 'School', selector: row => row.creator, selector_field:'school_name',sortable: true,width:'200px' },*/
        { name: 'Creator', selector: row => row.creator, selector_field:'creator',sortable: true,width:'200px' },
        { name: 'Designation', selector: row => row.designation, selector_field:'designation',sortable: true,width:'100px' },
        { name: 'Actions', cell: (row) => (
                <div className='action-components'>
                    <TableEditActions
                        row={row}
                        onEdit={() => { setSelectedSchoolContactPerson(row); setIsEditSchoolContactPersonOpen('edit'); }}
                    />
                    <TableDeleteActions
                        tooltip={"Delete"}
                        row={row}
                        onDelete={(row) => dispatch(callSoftDeleteDataWithLogFunction({ tableName: 'school_contact_person', id: row.id, userId: auth?.id, eventTypeId })).then(fetchData)}
                    />
                    { row.business_type_name !== 'School' &&
                        <Tippy content="Add Business Regarding">
                            <button className='action-button' onClick={() => { setSelectedSchoolContactPerson(row);  setIsEditSchoolContactPersonOpen('add_business_regarding'); }}>
                                <FontAwesomeIcon className='action-icon' icon={faBox} />
                            </button>
                        </Tippy>
                    }
                </div>
            ), width: '150px' }
    ];

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable]);
    // Update columns based on conditions
    useEffect(() => {
        let newColumns = [...defaultColumns];

        // Add "School" column based on page
        if (!selectedSchool) {
            newColumns.splice(1,0,{ name: 'Business Type', selector: row => row.business_type_name, selector_field:'business_type_name',sortable: true,width:'100px' });
            newColumns.splice(1,0,{ name: 'School', selector: row => row.school_name, selector_field:'school_name',sortable: true,width:'200px' });
         }

        setColumns(newColumns);
    }, [auth]);
    return (
        <>
            <DataTableLayout columns={columns} data={filteredData.data} dataTableSetting={settings} fetchData={fetchData} totalRows={filteredData?.total} />
            {isEditSchoolContactPersonOpen === 'edit' && (
                <SchoolContactPersonForm
                    handleExternalFetch={fetchData}
                    isOpen={isEditSchoolContactPersonOpen==='edit'}
                    onRequestClose={() => setIsEditSchoolContactPersonOpen(false)}
                    selectedData={selectedSchoolContactPerson}
                    isEditMode={true}
                />
            )}
            {isEditSchoolContactPersonOpen === 'add_business_regarding' && (
                <SchoolClientLeadForm
                    handleExternalFetch={fetchData}
                    isOpen={isEditSchoolContactPersonOpen=== 'add_business_regarding'}
                    onRequestClose={() => setIsEditSchoolContactPersonOpen(false)}
                    selectedData={{user_id:selectedSchoolContactPerson.id,business_type_id:selectedSchoolContactPerson.business_type_id}}
                    isEditMode={false}
                />
            )}
        </>
    );
};

export default SchoolContactPersonTable;
