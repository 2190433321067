import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchData } from '../../../hooks/useFetchData';
import {callSoftDeleteDataWithLogFunction, callUpdateDataWithLogFunction} from '../../../store/action/commonActions';
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import useAuth from "../../../hooks/useAuth";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import DailyReportForm from "./DailyReportForm";
import {indianDateFormat, indianDateTimeFormat, truncateHTMLText} from "../../../store/utility/DatatableUtility";
import Modal from "react-modal";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import SelectSalesPersonDropdown from "../../Sales/contact-person/SelectSalesPersonDropdown";
import DateField from "../../Layout/Form/DateField";
import Badge from "../../Layout/Badge";
import AttendanceForm from "./AttendanceForm";

const DailyReportTable = ({ setFetchDataTable, fetchDataTable }) => {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);
    const [advanceFilter, setAdvanceFilter] = useState({ });
    const allowedRoles = [1,9];

    const { data: schoolData, fetchData, settings } = useFetchData(`gallery/get-daily-report`, [], advanceFilter);
    const [isEditDailyReportOpen, setIsEditDailyReportOpen] = useState(false);
    const [selectedDailyReport, setSelectedDailyReport] = useState(null);
    const [columns, setColumns] = useState([]);
    const ROLES = useSelector((state) => state.webSetting.roleList);
    const [openBadge, setOpenBadge] = useState(null);
    const tableContainerRef = useRef(null);

    const defaultColumns = [
        { name: 'Sr No', selector: row => row.id, selector_field: 'id',
            cell: (row, index) => (
                <>
                    {index + 1}
                </>
            ), width: '60px' },
        { name: 'SOD Time', selector: row => row.sod_time, selector_field: 'sod_time', sortable: true, width: '150px' },
        { name: 'EOD Time', selector: row => row.eod_time, selector_field: 'eod_time', sortable: true, width: '150px' },
        { name: "Today's Work", selector: row =>  <div className="truncate-text" title="Click to view full content"
                                                     onClick={() => { setSelectedDailyReport(row); setIsEditDailyReportOpen('open_today_work_preview'); }}>
                {truncateHTMLText(row.today_work, 200)} {/* Adjust maxLength as needed */}
            </div>,
            selector_field: 'today_work', sortable: true, width: '500px' },
        { name: 'Created Date', selector: row => ([ROLES.SuperAdmin, ROLES.Accountant].includes(auth.role) || row.sod_time) ? indianDateTimeFormat(row.created_at) : indianDateFormat(row.report_date), selector_field: 'created_at', sortable: true },
        { name: 'Actions', cell: (row) => (
                <div className='action-components'>
                    {(allowedRoles || row.sod_time) && <TableEditActions
                        tooltip={"Edit"}
                        row={row}
                        onEdit={() => { setSelectedDailyReport(row); setIsEditDailyReportOpen((row.sod_time) ? 'edit' : 'update_attendance'); }}
                    />}
                    <TableDeleteActions
                        tooltip={"Delete"}
                        row={row}
                        onDelete={(row) => dispatch(callSoftDeleteDataWithLogFunction({ tableName: 'daily_report', id: row.id, userId: auth?.id, eventTypeId })).then(fetchData)}
                    />
                </div>
            ), width: '150px' }
    ];

    const handleExportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(schoolData.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Daily Reports");
        XLSX.writeFile(workbook, "DailyReports.xlsx");
    };

    const handleExportToPDF = () => {
        const doc = new jsPDF();
        const tableColumn = columns.map(col => col.name);
        const tableRows = schoolData.data.map(row => [
            row.id,
            row.user,
            row.sod_time,
            row.eod_time,
            row.today_work,
            row.reason,
            row.attendance_status,
            indianDateFormat(row.report_date)
        ]);
        doc.autoTable({ head: [tableColumn], body: tableRows });
        doc.save("DailyReports.pdf");
    };
    // Dropdown options memoized
    const dropdownOptions = useMemo(() => ({
        leave: [
            { value: 'Present', label: 'Present' },
            { value: 'Absent', label: 'Absent' },
            { value: 'Half Day', label: 'Half Day' },
            { value: 'Casual Leave', label: 'Casual Leave' },
            { value: 'Sick Leave', label: 'Sick Leave' },
            { value: 'Planned Leave', label: 'Planned Leave' },
        ]
    }), []);

    const handleDropdownToggle = useCallback((uniqueKey) => {
        setOpenBadge(prevKey => prevKey === uniqueKey ? null : uniqueKey);
    }, []);

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...advanceFilter,
            [name]: value
        };
        setAdvanceFilter(updatedFormData);
        setFetchDataTable(true);
    };

    const handleBadgeUpdate = useCallback((newStatus, key, id) => {
        const columnName = key.split('-')[0];
        const payload = { [columnName]: newStatus };
        dispatch(callUpdateDataWithLogFunction({
            tableName: 'daily_report',
            data: payload,
            id,
            userId: auth?.id,
            eventTypeId
        })).then(() => {
            setOpenBadge(null);
            fetchData();
        });
    }, [auth?.id, dispatch, eventTypeId, fetchData]);

    const getBadgeHtml = (key, label, color, options, id) => (
        <Badge
            key={key}
            label={label}
            color={color}
            options={options}
            isOpen={openBadge === key}
            onDropdownToggle={() => handleDropdownToggle(key)}
            uniqueKey={key}
            id={id}
            onUpdate={(newStatus) => handleBadgeUpdate(newStatus, key, id)}
            tableContainerRef={tableContainerRef}
        />
    );
    useEffect(() => {
        let newColumns = [...defaultColumns];
        if ([ROLES.SuperAdmin, ROLES.Accountant].includes(auth.role)) {
            newColumns.splice(1, 0, { name: 'User', selector: row => row.user, selector_field: 'user', sortable: true, width: '150px' });
            newColumns.splice(2, 0, { name: 'Created by', selector: row => row.creator, selector_field: 'creator', sortable: true, width: '150px' });
            newColumns.splice(3, 0, { name: 'Report Date', selector: row => indianDateFormat(row.report_date), selector_field: 'report_date', sortable: true, width: '150px' });
            newColumns.splice(4, 0,  {
                name: 'Status',sortable: true,
                selector: row => row.attendance_status,
                cell: (row, index) => getBadgeHtml(`attendance_status-${index}`, row.attendance_status,
                    ['Present','Half Day'].includes(row.attendance_status) ? 'green' : (['Casual Leave','Sick Leave','Planned Leave'].includes(row.attendance_status)) ? 'orange' :'red', dropdownOptions.leave, row.id),
                width: '100px'
            });
        }else{
            newColumns.splice(1, 0, { name: 'Attendance Status', selector: row => row.attendance_status, selector_field: 'attendance_status', sortable: true, width: '150px' });

        }

        setColumns(newColumns);
    }, [auth,dropdownOptions,openBadge]);

    return (
        <>
            <div className='text-right'>
            <div className="m-3">
                <button className='export-button' onClick={handleExportToExcel}>Export to Excel</button>
                <button className='export-button' onClick={handleExportToPDF}>Export to PDF</button>
            </div></div>
            {([ROLES.SuperAdmin, ROLES.Accountant].includes(auth.role)) &&
            <div className='ml-5 mt-3'>
                {/*<h1 className='text-xl'>Apply Filters:</h1>*/}
                <div className='vendor-details xl:flex lg:flex md:grid sm:grid justify-around'>
                    <div className='xl:flex xl:flex-row lg:flex lg:flex-row md:flex-col sm:flex-col'>
                        <SelectSalesPersonDropdown placeholder={"Select Employee"} name="dr.created_by" sales_person_id={advanceFilter['dr.created_by']} handleChange={handleChange} />
                    </div>
                    <DateField label="Date"
                               name={'dr.from_report_date'}
                               value={advanceFilter['dr.from_report_date'] || ''}
                               onChange={handleChange}
                               placeholder='From Date'/>
                    <DateField label="To Date"
                               name={'dr.to_report_date'}
                               value={advanceFilter['dr.to_report_date'] || ''}
                               onChange={handleChange}
                               placeholder='To Date' />
                </div>
            </div> }
            <DataTableLayout columns={columns} data={schoolData.data} dataTableSetting={settings} fetchData={fetchData} totalRows={schoolData?.total} tableContainerRef={tableContainerRef}/>
            {isEditDailyReportOpen === 'edit' && (
                <DailyReportForm
                    handleExternalFetch={fetchData}
                    isOpen={isEditDailyReportOpen === 'edit'}
                    onRequestClose={() => setIsEditDailyReportOpen(false)}
                    selectedData={selectedDailyReport}
                    isEditMode={true}
                />
            )} {isEditDailyReportOpen === 'update_attendance' && (
                <AttendanceForm
                    handleExternalFetch={fetchData}
                    isOpen={isEditDailyReportOpen === 'update_attendance'}
                    onRequestClose={() => setIsEditDailyReportOpen(false)}
                    selectedData={selectedDailyReport}
                    isEditMode={true}
                />
            )}
            {isEditDailyReportOpen === 'open_today_work_preview' &&
                <Modal
                    isOpen={isEditDailyReportOpen === 'open_today_work_preview'}
                    onRequestClose={() => setIsEditDailyReportOpen(false)}
                    className='Modal'
                >
                    <div className="container">
                        <div className='vendor-details'>
                            <h2>Full Work Details : {indianDateTimeFormat(selectedDailyReport?.created_at)}</h2>
                            <div dangerouslySetInnerHTML={{__html: selectedDailyReport.today_work}}></div>
                        </div>
                        <div className='buttons'>
                            <button className='close-button' onClick={() => setIsEditDailyReportOpen(false)}>Close</button>
                        </div>
                    </div>
                </Modal>}
        </>
    );
};

export default DailyReportTable;
