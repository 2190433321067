import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tippy from "@tippyjs/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFetchData } from "../../../hooks/useFetchData";
import useAuth from "../../../hooks/useAuth";
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import CompanyPipeline from "../../WebSetting/website_settings/company/CompanyPipeline";
import {faEye, faFile, faSitemap} from "@fortawesome/free-solid-svg-icons";
import { indianDateTimeFormat } from "../../../store/utility/DatatableUtility";
import { actionToGetCompanyListApiCall } from "../../../store/action/commonActions";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import PurchaseOrderItemModal from "./PurchaseOrderItemModal";
import {makeApiCall} from "../../../hooks/api/makeApiCall";
import {PURCHASE_ITEM_LIST_DATA} from "../../../store/constant/inventoryConstant";

const PurchaseDataTable = ({ setFetchDataTable, fetchDataTable }) => {
    const dispatch = useDispatch();
    const { auth } = useAuth();
    const eventTypeId = useSelector((state) => state.inventory?.inventoryEventTypeId);
    const [advanceFilter, setAdvanceFilter] = useState({ 'p.company_id': 2 });

    const { data: purchases, fetchData, settings } = useFetchData('inventory/get-purchase-orders-table-data', [], advanceFilter);

    const [isEditPurchaseOpen, setIsEditPurchaseOpen] = useState(null);
    const [selectedPurchase, setSelectedPurchase] = useState(null);

    const columns = useMemo(() => {
        const baseColumns = [
            { name: 'ID', selector: row => row.id, sortable: true },
            { name: 'Vendor', selector: row => row.vendor_name, sortable: true, width: '200px' },
            { name: 'Item List', cell: row => (<>
                    <Tippy content='View Item List'>
                        <button onClick={()=>{setIsEditPurchaseOpen('item_list');
                            getPurchaseOrderDetail(row); }} className='action-button'>
                            <FontAwesomeIcon className='action-icon' icon={faSitemap} />
                        </button>
                    </Tippy>
                </>) },
            { name: 'Status', selector: row => row.status, sortable: true },
            { name: 'Created Date', selector: row => row.created_at && indianDateTimeFormat(row.created_at), sortable: true },
            {
                name: 'Actions',
                cell: row => (
                    <>
                        <div className='action-buttons'>
                            <Tippy content='View Purchase Order'>
                                <button onClick={() => { openPurchaseDetailModal(row); }} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faEye} />
                                </button>
                            </Tippy>
                           {/* <Tippy content='View Items'>
                                <button onClick={() => { setIsEditPurchaseOpen('item_list');  getPurchaseOrderDetail(row); }} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faFile} />
                                </button>
                            </Tippy>*/}
                           {/* <Tippy content='Update Status'>
                                <button onClick={() => { setIsEditPurchaseOpen('update-status'); setSelectedPurchase(row); dispatch(actionToGetPurchaseOrderProcessingDetailApiCall({ id: row.id })); }} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faTruck} />
                                </button>
                            </Tippy>
                            <Tippy content='Upload Documents'>
                                <button onClick={() => { setIsEditPurchaseOpen('upload-document'); setSelectedPurchase(row); }} className='action-button'>
                                    <FontAwesomeIcon className='action-icon' icon={faFileUpload} />
                                </button>
                            </Tippy>*/}
                        </div>
                    </>
                ),
            },
        ];
        return baseColumns;
    }, [purchases]);
    const getPurchaseOrderDetail = (row) =>{
        makeApiCall(`inventory/get-purchase-order-detail`,PURCHASE_ITEM_LIST_DATA,dispatch,{ id: row.id });
    }
    const openPurchaseDetailModal = (row) => {
        setIsEditPurchaseOpen('view_purchase');
        getPurchaseOrderDetail(row);
    };

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable, fetchData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...advanceFilter,
            [name]: value
        };
        setAdvanceFilter(updatedFormData);
        setFetchDataTable(true);
    };

    useEffectOnce(() => {
        dispatch(actionToGetCompanyListApiCall({ in_purchase_use: 1 }));
    });

    return (
        <>
            <CompanyPipeline source={advanceFilter['p.company_id']} changeCompany={val => handleChange({ target: { name: 'p.company_id', value: val.id } })} />
            <DataTableLayout
                keyField="id"
                columns={columns}
                data={purchases.data}
                dataTableSetting={settings}
                fetchData={fetchData}
                totalRows={purchases?.total}
            />
            {isEditPurchaseOpen === 'item_list' && <PurchaseOrderItemModal isOpen={isEditPurchaseOpen === 'item_list'} selectedPurchase={selectedPurchase} fetchData={fetchData} onRequestClose={() => { setIsEditPurchaseOpen(false); setSelectedPurchase(false); }} />}
          {/*  {isEditPurchaseOpen === 'upload-document' && <PurchaseRelateFileUploadFormModal isOpen={isEditPurchaseOpen === 'upload-document'} selectedPurchase={selectedPurchase} onRequestClose={() => { setIsEditPurchaseOpen(false); setSelectedPurchase(false); }} />}
            {isEditPurchaseOpen === 'view_purchase' && <PurchaseModal isOpen={isEditPurchaseOpen === 'view_purchase'} onRequestClose={() => { setIsEditPurchaseOpen(false); }} />}
            {isEditPurchaseOpen === 'related-documents' && <PurchaseRelatedDataTable isOpen={isEditPurchaseOpen === 'related-documents'} selectedPurchase={selectedPurchase} onRequestClose={() => { setIsEditPurchaseOpen(false); setSelectedPurchase(false); }} />}*/}
        </>
    );
};

export default PurchaseDataTable;
