import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchData } from "../../../hooks/useFetchData";
import DataTableLayout from "../../Layout/DataTable/DataTableLayout";
import {actionToGetCompanyListApiCall, callSoftDeleteDataWithLogFunction} from "../../../store/action/commonActions";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import {makeApiCall} from "../../../hooks/api/makeApiCall";
import {PURCHASE_ITEM_LIST_DATA} from "../../../store/constant/inventoryConstant";
import TableEditActions from "../../Layout/DataTable/TableEditActions";
import TableDeleteActions from "../../Layout/DataTable/TableDeleteActions";
import AddSchoolUcDataModal from "./AddSchoolUcDataModal";
import {truncateHTMLText} from "../../../store/utility/DatatableUtility";
import Modal from "react-modal";
import useAuth from "../../../hooks/useAuth";

const SchoolUcTable = ({ setFetchDataTable, fetchDataTable }) => {
    const dispatch = useDispatch();
    const [advanceFilter, setAdvanceFilter] = useState({});
    const { auth } = useAuth();

    const { data: ucData, fetchData, settings } = useFetchData('sales/get-uc-order', [], advanceFilter);

    const [isEditUCDetailsOpen, setIsEditUCDetailsOpen] = useState(null);
    const [selectedSchoolUC, setSelectedSchoolUC] = useState(null);
    const [isPreviewRemarksOpen, setIsPreviewRemarksOpen] = useState(false);
    const eventTypeId = useSelector((state) => state.sales.salesEventTypeId);

    const columns = useMemo(() => {
        const baseColumns = [
            { name: 'Sr. No.', selector: row => row.id, sortable: true, width: '100px' },
            { name: 'School', selector: row => row.school_name, sortable: true, width: '350px' },
            { name: 'ATL Code', selector: row => row.atl_code, sortable: true, width: '250px' },
            { name: 'Login ID', selector: row => row.atl_login_id, sortable: true, width: '350px' },
            { name: 'UC Status', selector: row => row.uc_status, sortable: true, width: '200px' },
            { name: 'Remarks', selector: row =>  <div className="truncate-text" title={row.remarks} onClick={() => { setSelectedSchoolUC(row); setIsPreviewRemarksOpen('open_remark_preview'); }}>
                    {truncateHTMLText(row.remarks, 50)} {/* Adjust maxLength as needed */}
                </div>,
                selector_field:'remark',sortable: false ,width:'200px'},
            {
                name: 'Actions',
                cell: row => (
                    <div className='action-components'>
                        <TableEditActions
                            tooltip={"Edit"}
                            row={row}
                            onEdit={() => { setSelectedSchoolUC(row); setIsEditUCDetailsOpen('edit'); }}
                        />
                        <TableDeleteActions
                            tooltip={"Delete"}
                            row={row}
                            onDelete={() => dispatch(callSoftDeleteDataWithLogFunction({
                                tableName: 'school_documentation',
                                id: row.id,
                                userId: auth?.id,
                                eventTypeId
                            })).then(fetchData)}
                        />

                    </div>
                ),
            },
        ];
        return baseColumns;
    }, [ucData]);

    useEffect(() => {
        if (fetchDataTable) {
            setFetchDataTable(false);
            fetchData();
        }
    }, [fetchDataTable, setFetchDataTable, fetchData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = {
            ...advanceFilter,
            [name]: value
        };
        setAdvanceFilter(updatedFormData);
        setFetchDataTable(true);
    };

    useEffectOnce(() => {
        dispatch(actionToGetCompanyListApiCall({ in_purchase_use: 1 }));
    });

    return (
        <>
            <DataTableLayout
                keyField="id"
                columns={columns}
                data={ucData.data}
                dataTableSetting={settings}
                fetchData={fetchData}
                totalRows={ucData?.total}
            />
            {isEditUCDetailsOpen === 'edit' && (
                <AddSchoolUcDataModal
                    handleExternalFetch={fetchData}
                    isOpen
                    onRequestClose={() => setIsEditUCDetailsOpen(null)}
                    selectedData={selectedSchoolUC}
                    isEditMode
                />
            )}
            {isPreviewRemarksOpen === 'open_remark_preview' &&
                <Modal
                    isOpen={isPreviewRemarksOpen === 'open_remark_preview'}
                    onRequestClose={() => setIsPreviewRemarksOpen(false)}
                    className='Modal'
                >
                    <div className="container">
                        <div className='vendor-details'>
                            <h4>Full Remarks : </h4>
                            <div dangerouslySetInnerHTML={{__html: selectedSchoolUC.remarks}}></div>
                        </div>
                        <div className='buttons'>
                            <button className='close-button' onClick={() => setIsPreviewRemarksOpen(false)}>Close</button>
                        </div>
                    </div>
                </Modal>}
        </>
    );
};

export default SchoolUcTable;
