// components/SchoolForm.js
import React,{useState,useRef} from 'react';
import Modal from 'react-modal';
import InputField from '../../Layout/Form/InputField';
import TextArea from '../../Layout/Form/TextArea';
import TinyMceEditor from '../../Layout/Form/TinyMceEditor';
import {useCommonForm} from "../../../hooks/useCommonForm";
import {useSelector} from "react-redux";
import useAuth from "../../../hooks/useAuth";
import BreadCrumb from "../../Layout/Breadcrumb/BreadCrumb";
import TemplateVariableList from "./TemplateVariableList";

const TemplateForm = ({ isOpen, onRequestClose, selectedData, isEditMode,handleExternalFetch }) => {
    const {auth} = useAuth();
    const eventTypeId = useSelector((state) => state.marketing.marketingEventTypeId);
    const [isTemplateVariableListOpen, setIsTemplateVariableListOpen] = useState(false);
    const validationRules = {
        name: { required: true, minLength: 3, maxLength: 255 },
        subject: { required: true, minLength: 3, maxLength: 255 },
        html_content: { required: true, minLength: 50},
        text_content: { required: true, minLength: 50},
    };
    const options = ["{{user.name}}", "{{user.email}}", "{{user.mobileno}}", "{{school.name}}", "{{school.address}}", "{{school.mobileno}}"];

    const editorRef = useRef(null);

    const detectLastTwoCharacters = () => {
        if (editorRef.current) {
          const editor = editorRef.current;
          const selection = editor.selection;
    
          // Get the current range (cursor position)
          const range = selection.getRng();
          const container = range.startContainer;
    
          if (container.nodeType === Node.TEXT_NODE) {
            // Extract text up to the cursor position
            const textBeforeCursor = container.textContent.substring(
              0,
              range.startOffset
            );
    
            // Get the last two characters
            const lastTwoChars = textBeforeCursor.slice(-2);
            // console.log("Last two characters:", lastTwoChars);
             // Get the last three characters
             const lastThreeChars = textBeforeCursor.slice(-3);
            //  console.log("Last three characters:", lastThreeChars);
            return {lastTwoChars,lastThreeChars};
          }
    
          console.log("Cursor is not in a text node.");
          return {lastTwoChars:null,lastThreeChars:null};
        }
      };


    const insertTextAtCursor = (text) => {
        console.log(text,"Text")
      if (editorRef.current) {
        const editor = editorRef.current;
        // Get the current selection and cursor position
      const selection = editor.selection;
      const range = selection.getRng(); // Get the current range
        const markerId = `cursor_marker_${Date.now()}`;
        editor.selection.setRng(range);
        editor.insertContent(`${text}<span id="${markerId}"></span>`);
        // Move the cursor to the end of the inserted text
        const marker = editor.dom.select(`#${markerId}`)[0];
        if (marker) {
            setTimeout(()=>{
                editor.selection.select(marker);
                editor.dom.remove(marker); // Clean up the marker after moving the cursor
            });
        }
      }
      
    };

    const onSuccess = (res)=>{
        onRequestClose();
        handleExternalFetch?.(res,['tags']);

    }
    
    
    const handelAddVariable = (selectedVariable) =>{
        console.log(selectedVariable,"selectedVariable")
        insertTextAtCursor(selectedVariable.substring(2));
    }
    const { formData, errors, handleChange, handleSubmit, isFormChanged,setFormData } = useCommonForm(
        isEditMode, selectedData, onSuccess, eventTypeId, auth?.id
    );
    const changeOnEditor = (e)=>{
        let lastCharacterJson = detectLastTwoCharacters();
        if(lastCharacterJson.lastTwoChars==='{{' && lastCharacterJson.lastThreeChars!=='{{{'){
            setIsTemplateVariableListOpen(true);
        }else{
            setIsTemplateVariableListOpen(false);
        }
        setFormData((prev) => ({ ...prev, html_content:editorRef.current.getContent(), text_content: editorRef.current.getContent({ format: 'text' }) }));
    }
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className='Modal'>
            <div className="container max-h-[80vh] overflow-y-auto rounded-lg w-[90%]">
                <BreadCrumb first_crumb={isEditMode ? 'Edit Template' : 'Add Template'}/>
                <form onSubmit={(e) => handleSubmit(e,validationRules,'email_template',{created_by:auth?.id,html_content:formData.html_content},[])}>
                    <div className='vendor-details'>
                    <InputField
                        label="Name"
                        type="text"
                        name="name"
                        value={formData.name || ''}
                        onChange={handleChange}
                        error={errors.name} placeholder='Enter Template Name'
                    />
                    <InputField
                        label="Subject"
                        type="text"
                        name="subject"
                        value={formData.subject || ''}
                        onChange={handleChange}
                        error={errors.subject} placeholder='Enter Template Subject'
                    />
                    <TinyMceEditor 
                        label={"Template Content"} 
                        name="html_content" 
                        value={isEditMode? selectedData.html_content :''} 
                        onChange={changeOnEditor} 
                        error={errors.html_content} 
                        editorRef={editorRef}
                    />
                    <TextArea
                        label="Text Content"
                        name="text_content"
                        value={formData.text_content || ''}
                        onChange={handleChange}
                        error={errors.text_content} placeholder='Enter Email Text Content'
                        className='text-area'
                        disabled={true}
                    />
                    </div>
                    <div className='buttons'>
                        <button className='submit-button' type='submit' disabled={!isFormChanged}>
                            {isEditMode ? 'Update' : 'Submit'}
                        </button>
                        <button className='close-button' onClick={onRequestClose}>Close</button>
                    </div>
                </form>
                <TemplateVariableList
                isTemplateListOpen = {isTemplateVariableListOpen}
                onRequestVariableListClose = {() => setIsTemplateVariableListOpen(false)}
                options = {options}
                handelAddVariable = {(selectedVariable)=>handelAddVariable(selectedVariable)}
            />
            </div>
        </Modal>
    );
};

export default TemplateForm;
