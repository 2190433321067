import React from 'react'
import Card from './Card'
import {FaHandshake, FaHandshakeAltSlash, FaShoppingCart} from 'react-icons/fa'
import { dataLine, dataBar } from './chartData'
import {Line, Bar} from 'react-chartjs-2'
import {Chart as ChartJS, LineElement, BarElement, CategoryScale, LinearScale, PointElement} from 'chart.js'
import {useDispatch, useSelector} from "react-redux";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {makeApiCall} from "../../hooks/api/makeApiCall";
import {SALES_DASHBOARD_CARD_COUNT} from "../../store/constant/salesConstant";
import DashboardMyLeaveCount from "./DashboardMyLeaveCount";
ChartJS.register(LineElement, BarElement, CategoryScale, LinearScale, PointElement)

const Dashboard = () => {
    const dispatch =useDispatch();
    const salesDashboardData = useSelector((state) => state.sales.salesDashboardData);
    useEffectOnce(()=>{
        makeApiCall(`sales/get-admin-dashboard`,SALES_DASHBOARD_CARD_COUNT,dispatch);
    })

    return (
        <>

            <div className='grow p-8'>
            <h2 className='text-2xl mb-4'>Dashboard</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6'>
                <Card icon={<FaShoppingCart />} title="This Month Order" value={salesDashboardData.this_month_orders}/>
                <Card icon={<FaHandshakeAltSlash />} title="This Month Lead" value={salesDashboardData.this_month_leads}/>
                <Card icon={<FaShoppingCart />} title="Total Order" value={salesDashboardData.total_orders}/>
                <Card icon={<FaHandshake />} title="Total Lead" value={salesDashboardData.total_leads}/>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6'>
                <Card icon={<FaShoppingCart />} title="This Month My Order" value={salesDashboardData.this_month_my_orders}/>
                <Card icon={<FaHandshakeAltSlash />} title="This Month My Lead" value={salesDashboardData.this_month_my_leads}/>
                <Card icon={<FaShoppingCart />} title="Total My Order" value={salesDashboardData.total_my_orders}/>
                <Card icon={<FaHandshake />} title="Total My Lead" value={salesDashboardData.total_my_leads}/>
            </div>
            {/*<div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                <div className='bg-white p-4 dark:bg-gray-800 rounded-lg shadow-md'>
                    <h3 className='text-lg font-semibold mb-4'>Sales Data</h3>
                    <Line data={dataLine} />
                </div>
                <div className='bg-white p-4 dark:bg-gray-800 rounded-lg shadow-md'>
                    <h3 className='text-lg font-semibold mb-4'>Products Data</h3>
                    <Bar data={dataBar} />
                </div>
            </div>*/}
                <DashboardMyLeaveCount />
        </div>
        </>

    )
}

export default Dashboard
